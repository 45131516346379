import { render, staticRenderFns } from "./SidePanel.vue?vue&type=template&id=3b2f9fde&"
import script from "./SidePanel.vue?vue&type=script&lang=js&"
export * from "./SidePanel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SidePanelCloseButton: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/layout/SidePanelCloseButton.vue').default})
