//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed } from '@nuxtjs/composition-api'
const colors = [
  '#f18482',
  '#fdd47b',
  '#f9ef83',
  '#e6e77e',
  '#aad29e',
  '#8acdd7',
  '#84c4ec',
  '#8f94ea',
  '#a776b4',
  '#df7db3',
  '#e94340',
  '#f5962f',
  '#faea30',
  '#d0da30',
  '#7bbc62',
  '#50bec7',
  '#4889c5',
  '#45519f',
]

const roleColors = {
  ROLE_OWNER: 'bg-[#d9f3ea] border-[#d9f3ea] text-[#31a37a]',
  ROLE_ADMIN: 'bg-yellow-100 border-yellow-100 text-yellow-600',
  ROLE_ACCOUNTANT: 'bg-[#f8d3db] border-[#f8d3db] text-[#e45172]',
  ROLE_QUOTE_MANAGER: 'bg-blue-100 border-blue-100 text-blue-500',
  ROLE_SALES_MANAGER: 'bg-brand-100 border-brand-100 text-brand-500',
  ROLE_GHOST: 'bg-gray-50 border border-gray-200 !text-gray-600',
}

export default {
  props: {
    mono: Boolean,
    name: {
      type: String,
      default: '',
    },
    role: String,
    color: {
      type: String,
      default: 'bg-gray-400 text-white',
    },
    autocolor: Boolean,
    xs: Boolean,
    xl: Boolean,
  },
  setup(props) {
    const initials = computed(() =>
      props.name
        ? props.name
            .split(' ', props.mono ? 1 : 2)
            .map((word) => word[0])
            .join('')
            .trim()
        : '?'
    )

    function hashCode(str) {
      let hash = 0
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash)
      }
      return hash
    }

    function pickColor(str) {
      const index = Math.round(Math.abs(hashCode(str) % 360) / 20)
      return colors[index]
    }

    return { props, roleColors, initials, pickColor }
  },
}
