//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get as _get } from 'lodash-es'
import { ref, computed, defineComponent, provide, useContext } from '@nuxtjs/composition-api'

import { useUser } from '@/composables/user'
import useSidebar from '@/composables/sidebar'

import { withQuery } from 'ufo'
import useMq from '@/composables/mq'

export default defineComponent({
  setup() {
    const versionNumber = '1.9.25'

    const { sidebar } = useSidebar()
    const sidebarMap = computed(() =>
      sidebar.value
        .reduce((arr, el) => {
          arr = [].concat(arr, el.items)
          return arr
        }, [])
        .reduce((obj, val) => {
          if (val.items) {
            for (const child of val.items) {
              obj[child.key] = val.key
            }
          }
          return obj
        }, {})
    )

    const { user, company, subscription, can, isRoot } = useUser()

    const createCompanyModal = ref(null)
    const isStarter = computed(() => _get(subscription.value, 'plan.slug', null) === 'starter')
    const isTrial = computed(() => _get(subscription.value, 'status', null) === 'trialing')

    provide('sidebarMap', sidebarMap)

    const companies = computed(() => _get(user.value, 'companies', []))

    const switchCompany = (company) => {
      window.location.href = withQuery(window.location.href.replace('?invites=review', ''), {
        switch_company: company.id,
      })
    }

    const companyName = ref(null)

    const hasEllipsis = computed(() => {
      if (companyName.value) {
        return companyName.value.offsetWidth < companyName.value.scrollWidth
      }
      return false
    })

    return {
      can,
      sidebar,
      versionNumber,
      company,
      companies,
      user,
      isStarter,
      isTrial,
      subscription,
      companyName,
      hasEllipsis,
      createCompanyModal,
      switchCompany,
    }
  },
})
