//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get as _get } from 'lodash-es'
import { computed, defineComponent, useContext } from '@nuxtjs/composition-api'
export default defineComponent({
  props: {
    contact: {
      type: Object,
      default: () => {},
    },
    xs: Boolean,
    disableInteraction: Boolean,
    noIcon: Boolean,
    maxWidth: {
      type: String,
      default: 'max-w-[10rem]',
    },
  },
  setup(props) {
    const { $bus } = useContext()
    const handleOpenContact = () => {
      if (props.disableInteraction === false) {
        $bus.emit('openContact', props.contact)
      }
    }

    const fullName = computed(() => _get(props.contact, 'fullName', ''))

    return { props, handleOpenContact, fullName }
  },
})
