import { render, staticRenderFns } from "./MobileSidebar.vue?vue&type=template&id=00274628&"
import script from "./MobileSidebar.vue?vue&type=script&lang=js&"
export * from "./MobileSidebar.vue?vue&type=script&lang=js&"
import style0 from "./MobileSidebar.vue?vue&type=style&index=0&id=00274628&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/layout/Button.vue').default,Avatar: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/Avatar.vue').default,DropdownItem: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/layout/DropdownItem.vue').default,SidebarItem: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/layout/SidebarItem.vue').default})
