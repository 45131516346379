//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  defineComponent,
  nextTick,
  onBeforeUnmount,
  onMounted,
  ref,
  useContext,
  useFetch,
  watch,
} from '@nuxtjs/composition-api'
import { useLoading } from '@/composables/loading'
export default defineComponent({
  setup() {
    const { setLoading, isLoading } = useLoading()

    const { $eventsRepository } = useContext()
    const events = ref([])
    const scrollContainer = ref(null)
    const endOfList = ref(false)
    const page = ref(1)

    const { fetch } = useFetch(async () => {
      if (page.value === 1) {
        setLoading(true, 'notifications')
      }

      try {
        const { data, metadata } = await $eventsRepository.list({
          params: {
            notify: 1,
            _page: page.value,
            _expand: ['quote', 'invoice', 'project', 'order_form'],
            _order: 'desc',
            _sort: 'createdAt',
          },
        })
        events.value = [].concat(events.value, data)
        page.value += 1

        if (events.value.length >= metadata.items) {
          endOfList.value = true
          if (scrollContainer.value) {
            scrollContainer.value.scrollElement.removeEventListener('scroll', handleScroll)
          }
        }

        updateUser({ notificationsCount: 0 })
      } catch (err) {
      } finally {
        setLoading(false, 'notifications')
      }
    })

    const handleScroll = async (event) => {
      let { scrollTop, scrollHeight, clientHeight } = event.target

      if (!isLoading(`notifications_loadMore`) && scrollHeight - clientHeight - 100 < scrollTop && !endOfList.value) {
        setLoading(true, `notifications_loadMore`)
        await fetch()
        setLoading(false, `notifications_loadMore`)
      }
    }

    onMounted(() => {
      if (scrollContainer.value) {
        scrollContainer.value.scrollElement.addEventListener('scroll', handleScroll)
      }
    })

    onBeforeUnmount(() => {
      if (scrollContainer.value) {
        scrollContainer.value.scrollElement.removeEventListener('scroll', handleScroll)
      }
    })

    return { scrollContainer, events, isLoading }
  },
})
