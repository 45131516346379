//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, ref, computed, watch, nextTick } from '@nuxtjs/composition-api'
import { BubbleMenu } from '@tiptap/vue-2'
import { has as _has } from 'lodash-es'
import { vElementVisibility } from '@vueuse/components'

export default defineComponent({
  directives: {
    'element-visibility': vElementVisibility,
  },
  components: {
    BubbleMenu,
  },
  props: {
    toolbar: {
      type: Array,
      default: () => [],
    },
    editor: {
      type: Object,
      default: () => {},
    },
    toolbarClass: {
      type: String,
      default: '',
    },
    data: Object,
    tippyOptions: Object,
  },
  setup(props) {
    const showEdit = ref(false)
    const innerText = computed(() => {
      try {
        const { from, to } = props.editor.state.selection
        return props.editor.state.doc.textBetween(from, to, ' ')
      } catch (err) {
        return null
      }
    })

    const editText = ref(null)
    const editLink = ref(null)

    function onVisibility(state) {
      if (!state) showEdit.value = false
    }

    function toggleEdit() {
      try {
        editText.value = innerText.value
        editLink.value = props.editor.getAttributes('link').href
        showEdit.value = true
      } catch (err) {}
    }

    async function deleteLink() {
      showEdit.value = false
      await nextTick()
      props.editor.chain().focus().extendMarkRange('link').unsetLink().run()
    }

    async function update() {
      const { from, to } = props.editor.state.selection

      showEdit.value = false
      await nextTick()

      props.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: editLink.value })
        .insertContentAt({ from, to }, editText.value)
        .run()
    }

    watch(
      () => props.editor,
      (val) => {
        console.log(val)
      }
    )
    return { props, editText, editLink, showEdit, innerText, toggleEdit, deleteLink, onVisibility, update, _has }
  },
})
