//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed, defineComponent } from '@nuxtjs/composition-api'
import { isBefore } from 'date-fns'
import { get as _get } from 'lodash-es'
import { useUser } from '@/composables/user'
import { pluralizeType } from '@/api/resources'
import { kebabCase } from 'scule'
export default defineComponent({
  props: {
    event: Object,
  },
  setup(props) {
    const { user } = useUser()

    const eventObject = computed(() => {
      if (_get(props.event, 'quote', null)) return props.event.quote
      if (_get(props.event, 'invoice', null)) return props.event.invoice
      if (_get(props.event, 'orderForm', null)) return props.event.orderForm
      return null
    })

    const link = computed(() => {
      if (eventObject.value && eventObject.value.objectType) {
        const pluralType = pluralizeType[eventObject.value.objectType]

        let pathSuffix = _get(props.event, 'status', null) === 'draft' ? '' : '/details'
        if (_get(props.event, 'quote', null)) return `/${kebabCase(pluralType)}/${props.event.quote.id}${pathSuffix}`
        if (_get(props.event, 'invoice', null))
          return `/${kebabCase(pluralType)}/${props.event.invoice.id}${pathSuffix}`
        if (_get(props.event, 'orderForm', null))
          return `/${kebabCase(pluralType)}/${props.event.orderForm.id}${pathSuffix}`
      }

      return null
    })

    return { props, user, eventObject, link, isBefore }
  },
})
