import { cloneDeep as _cloneDeep, get as _get, has as _has, set as _set } from 'lodash-es'
import {
  computed,
  nextTick,
  reactive,
  ref,
  toRefs,
  useContext,
  useRouter,
  watch,
  watchEffect,
} from '@nuxtjs/composition-api'
import { useErrorState, useLoading } from '@/composables/loading'
import { makeDocumentPages } from '@/utils/preview'

import {
  AMOUNT_TYPE_SUBTOTAL,
  DOCUMENT_OPTION_DISPLAY_REFERENCE,
  DOCUMENT_OPTIONS,
  DOCUMENT_STATUS_ACCEPTED,
  DOCUMENT_STATUS_CANCELLED,
  DOCUMENT_STATUS_PAID,
  DOCUMENT_STATUS_REFUSED,
  DOCUMENT_SUBTYPE_DOWN_PAYMENT,
  DOCUMENT_SUBTYPE_FINAL,
  DOCUMENT_SUBTYPE_SITUATION,
  LINE_TYPE_GROUP,
  LINE_TYPE_LINE_BREAK,
  LINE_TYPE_PAGE_BREAK,
  LINE_TYPE_PRODUCT,
  LINE_TYPE_SUBTOTAL,
  LINE_TYPE_TEXT,
  ORDER_FORM,
  QUOTE,
} from '@/constants/documents'
import { pluralizeType } from '@/api/resources'

import { createNamespacedHelpers, useStore } from 'vuex-composition-helpers'
import { countHours, getDeductionValue, getDownPaymentValue } from '@/utils/documents'

import { makeStoreReadonlyProperties } from '@/utils/reactivity'
import { useDocumentDom } from '@/composables/dom'
import useMq from '@/composables/mq'
import { PRODUCT_TYPE_WORK_DETAILED, PRODUCT_TYPE_WORKFORCE } from '@/constants/products'
import { useUser } from '@/composables/user'
import { ROUNDING_METHOD_TOTAL } from '@/constants/companies'
import { roundToSix } from '@/utils/numbers'
import { toValue } from '@vueuse/core'
import { kebabCase } from 'scule'
import useUnit from '@/composables/units'
import { createGlobalState } from '@vueuse/core'

const { useGetters, useMutations, useActions, useState } = createNamespacedHelpers('documents')
const { useGetters: useTemplateGetters } = createNamespacedHelpers('templates')

export function usePreviewBox() {
  const { makeDocumentDom } = useDocumentDom()

  // Template refs
  const previewBox = ref(null)
  const previewBoxInner = ref(null)
  const blocksParent = ref(null)
  const previewRendering = ref(null)

  const initDocumentPreview = async (clear = false, event = null) => {
    if (previewBoxInner.value !== null) {
      if (typeof previewBoxInner.value !== 'undefined') {
        let before = Date.now()
        //console.log('[preview] init document preview with clear : ' + clear)
        await makeDocumentPages(clear, event, makeDocumentDom())
        updatePreviewSize(840)
        let after = Date.now()

        //console.log(after - before)
      }
    } else {
      console.log('error during preview init')
    }
  }

  const updatePreviewSize = (size = 840) => {
    /*
     * previewBoxInnerHeight :
     * Height of one page * number of pages (after breakdown) + ( number of pages * 20px margin)
     */
    if (typeof previewBoxInner.value !== 'undefined') {
      let previewBoxWidth = previewBoxInner.value.offsetWidth,
        previewContainers = previewBoxInner.value.getElementsByClassName('previewContainer'),
        previewBoxInnerHeight =
          previewContainers[0].offsetHeight * previewContainers.length + 20 * previewContainers.length,
        scale = previewBoxWidth / size,
        targetBoxHeight = previewBoxInnerHeight * scale,
        targetBoxMargin = targetBoxHeight - previewBoxInnerHeight

      previewBox.value.style.marginBottom = targetBoxMargin + 'px'
      previewBoxInner.value.style.transform = `scale(${Math.floor(scale * 100) / 100})`

      if (previewRendering.value !== null) {
        previewRendering.value.style.visibility = 'hidden'
        previewRendering.value.style.display = 'none'
      }

      let pageIndex = 1
      ;[].forEach.call(previewContainers, async (el) => {
        el.getElementsByClassName('documentCurrentPage')[0].innerHTML = pageIndex
        el.getElementsByClassName('documentPagesCount')[0].innerHTML = previewContainers.length
        el.style.visibility = 'visible'
        el.style.opacity = 1
        pageIndex++
      })
    }
  }

  return {
    previewBox,
    previewBoxInner,
    blocksParent,
    previewRendering,
    initDocumentPreview,
    updatePreviewSize,
  }
}

// Read-only document tree
export function usePreviewTree(context, props) {
  const { tree } = useState(['tree'])
  const previewTree = computed(() => tree.value)

  const hasLines = computed(() => !!Object.keys(previewTree.value).length)
  const firstOfTree = computed(() => previewTree.value[0] || null)

  return {
    previewTree,
    hasLines,
    firstOfTree,
  }
}

// Writeable document tree
export function useBuilderTree(context, props) {
  const { updateTree } = useMutations(['updateTree'])
  const { getTreeType } = useGetters(['getTreeType'])
  const { tree } = useState(['tree'])

  const builderTree = computed({
    get: () => tree.value,
    set: (val) => updateTree(val),
  })

  const state = reactive({
    hasLines: computed(() => !!Object.keys(builderTree.value).length),
    treeType: computed(() => getTreeType.value),
    builderTree,
  })

  return toRefs(state)
}

export function useDetailsLines(document) {
  const getLineComponent = (line) => {
    if (typeof line === 'undefined') {
      return
    }

    switch (line.type) {
      case LINE_TYPE_GROUP:
        return `DocumentDetailsLineGroup`
      case LINE_TYPE_TEXT:
        return `DocumentDetailsLineText`
      case LINE_TYPE_SUBTOTAL:
        return `` // todo DocumentDetailsLineSubtotal
      case LINE_TYPE_PAGE_BREAK:
      case LINE_TYPE_LINE_BREAK:
        return ``
      default:
        if (document.value.subtype === DOCUMENT_SUBTYPE_SITUATION && document.value.detailed) {
          return 'DocumentDetailsLineProductSituation'
        }
        return `DocumentDetailsLineProduct`
    }
  }

  const getIndexes = (lines) => {
    if (lines) {
      let index = 1
      let indexes = {}

      for (const line of lines) {
        indexes[line.id] = index
        ;(function makeChildIndexes(line, parentIndex) {
          const childLines = line.lines
          if (childLines && childLines.length) {
            let childIndex = 1
            for (const childLine of childLines) {
              indexes[childLine.id] = `${parentIndex}.${childIndex}`
              makeChildIndexes(childLine, `${parentIndex}.${childIndex}`)
              if (
                childLine &&
                ![LINE_TYPE_TEXT, LINE_TYPE_PAGE_BREAK, LINE_TYPE_LINE_BREAK, LINE_TYPE_SUBTOTAL].includes(
                  childLine.type
                )
              ) {
                ++childIndex
              }
            }
          }
        })(line, index)
        if (
          line &&
          ![LINE_TYPE_TEXT, LINE_TYPE_PAGE_BREAK, LINE_TYPE_LINE_BREAK, LINE_TYPE_SUBTOTAL].includes(line.type)
        ) {
          ++index
        }
      }

      /*for (const line of lines) {
        if (line && ![LINE_TYPE_TEXT, LINE_TYPE_PAGE_BREAK, LINE_TYPE_LINE_BREAK, LINE_TYPE_SUBTOTAL].includes(line.type)) {
          const [lastIndex] = Object.values(indexes).slice(-1)
          indexes[line.id] = lastIndex ? parseInt(lastIndex) + 1 : 1
        }
      }*/
      return indexes
    }
    return {}
  }

  return { getLineComponent, getIndexes }
}

// Document lines data + totals
export function useDocumentLines(context, props) {
  const { mq } = useMq()
  const { lines, subtype, detailed, object } = makeStoreReadonlyProperties('documents', [
    'lines',
    'subtype',
    'detailed',
    'object',
  ])
  const { getLine } = useGetters(['getLine'])

  const getLineComponent = (key) => {
    const line = getLine.value[key]

    if (typeof line === 'undefined') {
      return
    }

    let device = ['xs', 'sm', 'md'].includes(mq.value) ? 'Mobile' : ''

    let lineTypeSuffix = ''
    switch (subtype.value) {
      case DOCUMENT_SUBTYPE_DOWN_PAYMENT:
      case DOCUMENT_SUBTYPE_SITUATION:
        lineTypeSuffix = 'Immutable'
        break
    }

    if (!line.editable && line.type !== LINE_TYPE_GROUP) {
      lineTypeSuffix = 'Immutable'
    }

    if (line.additional) {
      lineTypeSuffix = ''
    }

    switch (line.type) {
      case LINE_TYPE_GROUP:
        if (!line.additional && detailed.value === true && subtype.value === DOCUMENT_SUBTYPE_SITUATION)
          lineTypeSuffix = 'Situation'
        return `DocumentBuilderLineGroup${lineTypeSuffix}`
      case LINE_TYPE_TEXT:
        return `DocumentBuilderLine${device}Text${lineTypeSuffix}`
      case LINE_TYPE_PAGE_BREAK:
        return `DocumentBuilderLinePageBreak`
      case LINE_TYPE_LINE_BREAK:
        return `DocumentBuilderLineBreak`
      case LINE_TYPE_SUBTOTAL:
        return `DocumentBuilderLineSubtotal`
      default:
        if (detailed.value === true && subtype.value === DOCUMENT_SUBTYPE_SITUATION) lineTypeSuffix = 'Situation'
        if (object.value === ORDER_FORM) lineTypeSuffix = 'OrderForm'
        return `DocumentBuilderLine${device}Product${lineTypeSuffix}`
    }
  }

  const getLineDragType = (key) => {
    const line = getLine.value[key]

    if (typeof line === 'undefined') {
      return null
    }

    return line.type
  }

  const getIndexes = (tree) => {
    if (tree) {
      return tree.reduce((obj, key) => {
        const line = getLine.value[key]
        if (
          line &&
          ![LINE_TYPE_TEXT, LINE_TYPE_PAGE_BREAK, LINE_TYPE_LINE_BREAK, LINE_TYPE_SUBTOTAL].includes(line.type)
        ) {
          const [lastIndex] = Object.values(obj).slice(-1)
          obj[key] = lastIndex ? parseInt(lastIndex) + 1 : 1
        }
        return obj
      }, {})
    }
    return {}
  }

  return {
    lines,
    getLine,
    getIndexes,
    getLineDragType,
    getLineComponent,
  }
}

export function useDocumentDownPayments() {
  const { downPayments } = useState(['downPayments'])

  const downPaymentsValues = (total) => {
    let downPaymentsTotal = 0
    let arr = []

    for (const key of Object.keys(downPayments.value)) {
      let currentAmount = getDownPaymentValue(total.total, downPayments.value[key])

      arr.push({
        ..._cloneDeep(downPayments.value[key]),
        ...{ total: currentAmount },
      })

      downPaymentsTotal += currentAmount
    }

    return arr
  }

  return { downPaymentsValues }
}

export function useDocumentDeductions() {
  const { totalDeductions, subtotalDeductions } = useState(['totalDeductions', 'subtotalDeductions'])

  const totalDeductionsValues = (total) => {
    let deductionsTotal = 0
    let arr = []

    for (const key of Object.keys(totalDeductions.value)) {
      let currentAmount = getDeductionValue(total.total, totalDeductions.value[key])

      arr.push({
        ..._cloneDeep(totalDeductions.value[key]),
        ...{ total: currentAmount },
      })

      deductionsTotal += currentAmount
    }

    return arr
  }

  const subtotalDeductionsValues = (total) => {
    let deductionsTotal = 0
    let arr = []

    for (const key of Object.keys(subtotalDeductions.value)) {
      let currentAmount = getDeductionValue(total.discountedSubtotal, subtotalDeductions.value[key])

      arr.push({
        ..._cloneDeep(subtotalDeductions.value[key]),
        ...{ total: currentAmount },
      })

      deductionsTotal += currentAmount
    }

    return arr
  }

  return { subtotalDeductionsValues, totalDeductionsValues }
}

// Builder lines / group actions
export function useBuilderActions(context, props) {
  const { addLine, addGroup } = useActions(['addLine', 'addGroup'])

  async function handleAddGroup(params = { parent: null, sibling: null }) {
    const key = await addGroup(params)
    await nextTick()

    let group = window.document.getElementById(`document_group_${key}`)
    if (group) {
      group.focus()
    }
  }

  async function handleAddLine(params = { type: 'product', productType: null, parent: null, sibling: null }) {
    params.autofocus = true
    const key = await addLine(params)
    await nextTick()
    const currentEditor = window.document.querySelector(`#document_line_${key} .ProseMirror`)
    if (currentEditor && typeof currentEditor.focus === 'function') {
      currentEditor.focus()
    }
  }

  return { addLine, handleAddGroup, handleAddLine }
}

export function usePreviewData(document) {
  return useState([
    'id',
    'object',
    'objectType',
    'paymentMethods',
    'downPayments',
    'reverseCharge',
    'taxable',
    'type',
    'footer',
    'number',
    'readOnly',
    'project',
    'customer',
  ])
}

export function useDocumentStatus() {
  const { subtype } = useState(['subtype'])
  const isLocked = computed(() => [DOCUMENT_SUBTYPE_DOWN_PAYMENT, DOCUMENT_SUBTYPE_SITUATION].includes(subtype.value))
  return { isLocked }
}

export const useDocumentOptions = createGlobalState(() => {
  const { options } = useState(['options'])

  const documentOptions = computed(() => {
    return Object.entries(DOCUMENT_OPTIONS).reduce((obj, [k, v]) => {
      for (const scope of v) {
        _set(obj, `${scope}.${k}`, options.value[`${scope}_${k}`])
      }
      return obj
    }, {})
  })

  const hasOption = (scope, option) => _get(documentOptions.value, `${scope}.${option}`, false)

  return {
    documentOptions,
    hasOption,
  }
})

export function useDetailsTotal(document) {
  const getTotalComponent = computed(() => {
    if (document.value.reverseCharge) {
      return 'DocumentDetailsTotalSimple'
    }
    return document.value.taxable ? 'DocumentDetailsTotalSimpleTaxable' : 'DocumentDetailsTotalSimple'
  })

  return { getTotalComponent }
}

export function useStatus($Repository, document) {
  const { app, $bus, $toast } = useContext()
  const { setLoading } = useLoading()
  const { setErrorState } = useErrorState()

  const setStatus = async function (document, status, $overrideRepository = null) {
    let $repository = $overrideRepository || $Repository
    const pluralType = pluralizeType[document.object]
    return new Promise(async (resolve, reject) => {
      if (!$repository) {
        reject('no_repository')
      }

      try {
        const { data } = await $repository.put(document.id, `status/${status}`, {}, { cdn: 1 })
        if (_get(data, 'success') === false) {
          let errors = _get(data, 'errors', [])

          $toast.error(
            errors.length
              ? `<div><strong>Impossible de mettre à jour le document</strong><br />
                ${errors.map((error) => app.i18n.t(`${pluralType}.error.open.${errors[0]}`)).join('<br />')}</div>`
              : 'Impossible de mettre à jour le document, veuillez réessayer ultérieurement'
          )

          reject()
        } else {
          $toast.show(app.i18n.t(`${pluralType}.notice.statusChanged.${status}`))
          resolve(data)
        }
      } catch (err) {
        $toast.show(app.i18n.t('layout.errors.unknown'))
        reject(err)
      }
    })
  }

  const changeStatus = async (status) => {
    try {
      document = toValue(document)
      setLoading(true, status)
      const data = await setStatus(document, status)

      if (!_has(data, 'errors')) {
        $bus.emit('update.item', { data, type: document.objectType })
        $bus.emit('reload.events')
      }
    } catch (err) {
      console.log(err)
      setErrorState(status)
    } finally {
      setLoading(false, status)
    }
  }

  const handleStatusChange = async ($event) => {
    const status = $event.status
    document.value.status = status
    setTransitionDate(status, $event.date)
    $bus.emit('reload.events')
  }

  const handleSent = async () => {
    document.value.sentAt = new Date()
    $bus.emit('reload.events')
  }

  const setTransitionDate = (status, date) => {
    switch (status) {
      case DOCUMENT_STATUS_REFUSED:
        document.value.refusedAt = date
        break
      case DOCUMENT_STATUS_ACCEPTED:
        document.value.acceptedAt = date
        break
      case DOCUMENT_STATUS_PAID:
        document.value.paidAt = date
        break
    }
  }

  return { setStatus, changeStatus, handleStatusChange, handleSent, setTransitionDate }
}

export function useDocumentActions($Repository, emit = () => {}) {
  const { app, $toast, $bus } = useContext()
  const router = useRouter()
  const { setLoading } = useLoading()

  const archive = async (document, archive = true, hide = () => {}) => {
    try {
      setLoading(true, `${archive ? '' : 'un'}archive_document_${document.id}`)
      const { data } = await $Repository.put(document.id, 'update', { archived: archive })
      if (archive) {
        $bus.emit('delete.item', { object: data })
      } else {
        $bus.emit('create.item', { data, type: document.type })
      }
      emit('archived', archive)
      $toast.show(app.i18n.t(`documents.notice.${archive ? 'archived' : 'unarchived'}`))
      hide()
    } catch (err) {
    } finally {
      setLoading(false, `${archive ? '' : 'un'}archive_document_${document.id}`)
    }
  }

  const downloadPDF = async (id, action = 'download', format = null) =>
    new Promise(async (resolve) => {
      try {
        setLoading(true, action)

        if (format) {
        }
        const { data } = await $Repository.get(id, 'download')
        if (data.url) {
          window.open(action === 'download' ? `${data.url}&download=1` : data.url)
        }
        $bus.emit('prompt_mark_as_sent.item', id)
        setLoading(false, action)
        resolve(true)
      } catch (err) {
        setLoading(false, action)
        resolve(false)
      }
    })

  const duplicate = async function (document) {
    const pluralType = pluralizeType[document.object]

    try {
      setLoading(true, 'duplicate')
      const { data } = await $Repository.get(document.id, 'duplicate')
      if (data.id) {
        $toast.show(app.i18n.t(`${pluralType}.notice.duplicated`))
        await router.push(`/${kebabCase(pluralType)}/${data.id}`)
      }
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false, 'duplicate', 2000)
    }
  }

  const createRevision = async (document) => {
    const pluralType = pluralizeType[document.object]

    try {
      setLoading(true, 'createRevision')
      const { data } = await $Repository.get(document.id, 'duplicate', { revision: true })
      if (data.id) {
        await router.push(`/${kebabCase(pluralType)}/${data.id}`)
      }
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false, 'createRevision', 2000)
    }
  }

  return { downloadPDF, duplicate, archive, createRevision }
}

const getColumnClasses = (d, additional_lines, display_reference) => {
  const classes = {
    description: 'lg:col-span-9',
    rest: 'lg:col-span-7',
  }

  const document = toValue(d)
  const subtype = toValue(document.subtype)
  const taxable = toValue(document.taxable)
  const reverseCharge = toValue(document.reverseCharge)
  const displayReference = toValue(display_reference)
  const hasAdditionalLines = toValue(additional_lines)
  const detailed = toValue(document.detailed)

  switch (subtype) {
    case DOCUMENT_SUBTYPE_DOWN_PAYMENT:
      // Containers
      classes.description = 'lg:col-span-10'
      classes.rest = 'lg:col-span-6 justify-end'
      // Fields
      classes.tax = taxable && !reverseCharge ? 'w-12/30' : ''
      classes.subtotal = taxable && !reverseCharge ? 'w-18/30 text-right' : 'w-full text-right'
      break
    case DOCUMENT_SUBTYPE_SITUATION:
      if (detailed) {
        // Containers
        if (_get(document, 'subNumber.value', 0) > 1) {
          classes.description = 'lg:col-span-6'
          classes.rest = ['justify-end'].concat(['lg:col-span-10']).join(' ')
        } else {
          classes.description = 'lg:col-span-8'
          classes.rest = ['justify-end'].concat(['lg:col-span-8']).join(' ')
        }

        // Fields
        if ((hasAdditionalLines || Object.keys(_get(document, 'taxes', {})).length > 1) && taxable && !reverseCharge) {
          if (_get(document, 'subNumber.value', 0) > 1) {
            classes.tax = 'w-4/30' // 6
            classes.quoted = 'w-5/30' // 6
            classes.progression = 'w-5/30' // 12
            classes.accumulation = 'w-5/30' // 18
            classes.already_invoiced = 'w-5/30' // 24
            classes.subtotal = 'w-6/30' // 30
          } else {
            classes.tax = 'w-5/30' // 5
            classes.quoted = 'w-6/30' // 11
            classes.progression = 'w-6/30' // 17
            classes.accumulation = 'w-6/30' // 23
            classes.subtotal = 'w-7/30' // 30
          }
        } else {
          if (_get(document, 'subNumber.value', 0) > 1) {
            classes.quoted = 'w-6/30' // 6
            classes.progression = 'w-6/30' // 12
            classes.accumulation = 'w-6/30' // 18
            classes.already_invoiced = 'w-6/30' // 24
            classes.subtotal = 'w-6/30' // 30
          } else {
            classes.quoted = 'w-8/30' // 6
            classes.progression = 'w-7/30' // 12
            classes.accumulation = 'w-7/30' // 18
            classes.subtotal = 'w-8/30' // 30
          }
        }
      } else {
        // Containers
        classes.description = 'lg:col-span-10'
        classes.rest = 'lg:col-span-6 justify-end'
        // Fields
        classes.sellPrice = taxable && !reverseCharge ? 'w-12/30' : 'w-15/30'
        classes.tax = taxable && !reverseCharge ? 'w-6/30' : ''
        classes.subtotal = taxable && !reverseCharge ? 'w-12/30' : 'w-15/30'
      }
      break
    default:
      classes.description = 'lg:col-span-9'
      classes.rest = 'lg:col-span-7'

      if (taxable && !reverseCharge) {
        if (!displayReference) {
          // Containers
          classes.description = 'lg:col-span-8'
          classes.rest = 'lg:col-span-8'
          // Fields
          classes.quantity = 'w-5/30' // 5
          classes.unit = 'w-5/30' // 10
          classes.sellPrice = 'w-7/30 text-right' // 17
          classes.tax = 'w-5/30' // 22
          classes.subtotal = 'w-8/30 text-right' // 30
        } else {
          // Containers
          classes.description = 'lg:col-span-7'
          classes.rest = 'lg:col-span-9'
          // Fields
          classes.reference = 'w-6/30' // 6
          classes.quantity = 'w-5/30' // 11
          classes.unit = 'w-3/30' // 14
          classes.sellPrice = 'w-5/30 text-right' // 19
          classes.tax = 'w-5/30' // 24
          classes.subtotal = 'w-6/30 text-right' // 30
        }
      } else {
        if (!displayReference) {
          // Containers
          classes.description = 'lg:col-span-9'
          classes.rest = 'lg:col-span-7'
          // Fields
          classes.quantity = 'w-6/30' // 6
          classes.unit = 'w-6/30' // 12
          classes.sellPrice = 'w-8/30 text-right' // 20
          classes.subtotal = 'w-10/30 text-right' // 30
        } else {
          // Containers
          classes.description = 'lg:col-span-8'
          classes.rest = 'lg:col-span-8'
          // Fields
          classes.reference = 'w-5/30' // 5
          classes.quantity = 'w-6/30' // 11
          classes.unit = 'w-4/30' // 15
          classes.sellPrice = 'w-7/30 text-right' // 22
          classes.subtotal = 'w-8/30 text-right' // 30
        }
      }
  }

  classes.quantity += ' shrink-0'
  classes.reference += ' shrink-0'
  classes.tax += ' shrink-0'
  classes.unit += ' shrink-0'
  classes.sellPrice += ' shrink-0'
  classes.subtotal += ' shrink-0'

  return classes
}

const getDetailsColumnClasses = (d, display_reference, display_margins) => {
  const classes = {
    description: 'lg:col-span-9',
    rest: 'lg:col-span-7',
  }

  const document = toValue(d)
  const subtype = toValue(document.subtype)
  const taxable = toValue(document.taxable)
  const reverseCharge = toValue(document.reverseCharge)
  const displayReference = toValue(display_reference)
  const displayMargins = toValue(display_margins)
  const detailed = toValue(document.detailed)

  switch (subtype) {
    case DOCUMENT_SUBTYPE_DOWN_PAYMENT:
      // Containers
      classes.description = 'col-span-10'
      classes.rest = 'col-span-6'
      // Fields
      classes.tax = taxable && !reverseCharge && Object.keys(document.taxAmounts).length > 1 ? 'w-12/30' : ''
      classes.subtotal = taxable && !reverseCharge && Object.keys(document.taxAmounts).length > 1 ? 'w-18/30' : 'w-full'
      break
    case DOCUMENT_SUBTYPE_SITUATION:
      if (detailed) {
        if (_get(document, 'subNumber', 0) > 1) {
          // Containers
          classes.description = 'col-span-7'
          classes.rest = ['justify-end'].concat(['col-span-9']).join(' ')
          // Fields
          classes.quoted = 'w-6/30' // 7
          classes.already_invoiced = 'w-6/30' // 7
          classes.progression = 'w-6/30' // 14
          classes.accumulation = 'w-6/30' // 21
          classes.subtotal = 'w-6/30' // 30
        } else {
          // Containers
          classes.description = 'col-span-8'
          classes.rest = ['justify-end'].concat(['col-span-8']).join(' ')
          // Fields
          classes.quoted = 'w-7/30' // 7
          classes.progression = 'w-7/30' // 14
          classes.accumulation = 'w-8/30' // 21
          classes.subtotal = 'w-8/30' // 30
        }
      } else {
        // Containers
        classes.description = 'col-span-10'
        classes.rest = 'col-span-6  justify-end'
        // Fields
        classes.sellPrice =
          taxable && !reverseCharge && Object.keys(document.taxAmounts).length > 1 ? 'w-12/30' : 'w-15/30'
        classes.tax = taxable && !reverseCharge && Object.keys(document.taxAmounts).length > 1 ? 'w-6/30' : ''
        classes.subtotal =
          taxable && !reverseCharge && Object.keys(document.taxAmounts).length > 1 ? 'w-12/30' : 'w-15/30'
      }
      break
    default:
      classes.description = 'col-span-9'
      classes.rest = 'col-span-7'

      if (document.objectType === ORDER_FORM) {
        if (taxable && !reverseCharge && Object.keys(document.taxAmounts).length > 1) {
          // Containers
          classes.description = 'col-span-8'
          classes.rest = 'col-span-8'
          // Fields
          if (displayReference) {
            classes.reference = 'w-6/30'
            classes.quantity = 'w-4/30'
            classes.tax = 'w-5/30'
            classes.sellPrice = 'w-7/30'
            classes.subtotal = 'w-8/30'
          } else {
            classes.quantity = 'w-6/30'
            classes.tax = 'w-8/30'
            classes.sellPrice = 'w-8/30'
            classes.subtotal = 'w-8/30'
          }
        } else {
          // Containers
          classes.description = 'col-span-9'
          classes.rest = 'col-span-7'
          // Fields
          if (displayReference) {
            classes.reference = 'w-8/30'
            classes.quantity = 'w-6/30'
            classes.sellPrice = 'w-8/30'
            classes.subtotal = 'w-8/30'
          } else {
            classes.quantity = 'w-10/30'
            classes.sellPrice = 'w-10/30'
            classes.subtotal = 'w-10/30'
          }
        }
      } else {
        if (taxable && !reverseCharge && Object.keys(document.taxAmounts).length > 1) {
          // Containers
          classes.description = 'col-span-7'
          classes.rest = 'col-span-9'
          // Fields
          if (displayReference) {
            classes.reference = 'w-6/30' // 8
            classes.quantity = 'w-4/30' // 8
            classes.tax = displayMargins ? 'w-4/30' : 'w-5/30' // 22
            classes.sellPrice = displayMargins ? 'w-5/30' : 'w-7/30' // 22
            classes.buyPrice = 'w-5/30'
            classes.subtotal = displayMargins ? 'w-6/30' : 'w-8/30' // 30
          } else {
            classes.quantity = displayMargins ? 'w-5/30' : 'w-6/30' // 8
            classes.tax = displayMargins ? 'w-5/30' : 'w-8/30' // 22
            classes.sellPrice = displayMargins ? 'w-7/30' : 'w-8/30' // 22
            classes.buyPrice = 'w-7/30'
            classes.subtotal = displayMargins ? 'w-6/30' : 'w-8/30' // 30
          }
        } else {
          // Containers
          classes.description = displayMargins ? 'col-span-8' : 'col-span-9'
          classes.rest = displayMargins ? 'col-span-8' : 'col-span-7'
          // Fields
          if (displayReference) {
            classes.reference = 'w-5/30'
            classes.quantity = displayMargins ? 'w-4/30' : 'w-8/30' // 20
            classes.sellPrice = displayMargins ? 'w-7/30' : 'w-8/30' // 20
            classes.buyPrice = 'w-7/30'
            classes.subtotal = displayMargins ? 'w-7/30' : 'w-9/30' // 30
          } else {
            classes.quantity = displayMargins ? 'w-6/30' : 'w-10/30' // 20
            classes.sellPrice = displayMargins ? 'w-8/30' : 'w-10/30' // 20
            classes.buyPrice = 'w-8/30'
            classes.subtotal = displayMargins ? 'w-8/30' : 'w-10/30' // 30
          }
        }
      }
  }

  classes.quantity += ' shrink-0'
  classes.reference += ' shrink-0'
  classes.tax += ' shrink-0'
  classes.unit += ' shrink-0'
  classes.sellPrice += ' shrink-0'
  classes.buyPrice += ' shrink-0'
  classes.subtotal += ' shrink-0'
  classes.rest += ' text-right'

  return classes
}

export const useDocumentBuilderColumns = createGlobalState(() => {
  const document = useState(['subtype', 'subNumber', 'taxable', 'taxes', 'reverseCharge', 'detailed'])
  const { hasAdditionalLines } = useGetters(['hasAdditionalLines'])

  const documentOptions = useDocumentOptions()
  const displayReference = computed(() => documentOptions.hasOption('builder', DOCUMENT_OPTION_DISPLAY_REFERENCE))

  const getNumberingClass = (indexLength) => {
    if (indexLength.value <= 2) {
      return 'w-10'
    }
    if (indexLength.value <= 3) {
      return 'w-14'
    }
    if (indexLength.value <= 4) {
      return 'w-16'
    }
    if (indexLength.value <= 6) {
      return 'w-[4.25rem]'
    }
    return 'w-20'
  }

  const columnsClasses = computed(() => getColumnClasses(document, hasAdditionalLines, displayReference))

  return { columnsClasses, getNumberingClass }
})

export function useDocumentDetailsColumns(document, displayMargins) {
  const displayReference = _get(document.value, 'options.document_display_reference', false)
  return computed(() => getDetailsColumnClasses(document, displayReference, displayMargins))
}

export function useCurrency() {
  const { company } = useUser()

  const round = (value, currency) => {
    if (['XAF', 'XOF', 'XPF'].includes((currency || company.value.currency).toUpperCase())) {
      return Math.round(Math.round(value / 100) * 100)
    }

    return Math.round(value)
  }

  return { round }
}

export function useDocumentTotal() {
  const { isSituationComplete, getAncestors, getLineTotal, getLineTotal2 } = useGetters([
    'isSituationComplete',
    'getAncestors',
    'getLineTotal',
    'getLineTotal2',
  ])

  const { company } = useUser()
  const Currency = useCurrency()

  const store = useStore()
  const state = computed(() => store.state.documents)

  const findAncestors = (state, key) => {
    let ancestors = []
    let parentKey = Object.keys(state.childTree).find((el) => _get(state.childTree, el, []).includes(key))
    if (parentKey) {
      ancestors.push(parentKey)
      ancestors.push(findAncestors(state, parentKey))
    }
    return ancestors
  }

  const calculateTotalsWithoutTax = (state, lines, totals, deductedDocuments) => {
    for (const data of lines) {
      const { lineTotal, ...line } = data

      /*
        let ancestors = findAncestors(state, line.key)

       // TODO replace with loop on ancestors to check for optional
        let parentKey = Object.keys(state.childTree).find((key) => _get(state.childTree, key, []).includes(line.key))
        if (parentKey) {
          let parent = state.lines.find((el) => el.key === parentKey)
          if (parent && parent.optional) continue
        }*/

      const ancestors = getAncestors.value(line.key)
      if (ancestors.length && ancestors.some((el) => el.optional === true)) continue

      if (line.optional) continue
      if (lineTotal === undefined) continue

      // Total lines before discounts, deductions and taxes
      totals.grossSubtotal += Currency.round(lineTotal.subtotal)
      totals.plannedGrossSubtotal += Currency.round(lineTotal.plannedSubtotal)
    }

    // Applies AMOUNT discount
    if (state.amountOff && state.amountOff > 0) {
      totals.discountTotal = state.amountOff
    }

    // Applies PERCENT discount
    if (state.percentOff && state.percentOff > 0) {
      totals.discountTotal = Currency.round(totals.grossSubtotal * (state.percentOff / 100))
      totals.plannedDiscountTotal = Currency.round(totals.plannedGrossSubtotal * (state.percentOff / 100))
    }

    totals.discountedSubtotal = totals.grossSubtotal - totals.discountTotal
    totals.plannedDiscountedSubtotal = totals.plannedGrossSubtotal - totals.plannedDiscountTotal

    totals.salesSubtotal = totals.grossSubtotal - totals.discountTotal

    let deductedTotal = 0
    // Applies SUBTOTAL deductions
    if (totals.discountedSubtotal > 0 && Object.keys(state.subtotalDeductions).length > 0) {
      for (const key of Object.keys(state.subtotalDeductions)) {
        const deduction = _cloneDeep(state.subtotalDeductions[key])
        let amount = deduction.amount
        if (['minus', undefined].includes(deduction.direction)) amount = Math.abs(amount) * -1
        if (deduction.amountType === AMOUNT_TYPE_SUBTOTAL) {
          deductedTotal += amount
        } else {
          deductedTotal += Currency.round(totals.discountedSubtotal * (amount / 100))
        }
      }
    }

    totals.subtotal = totals.discountedSubtotal + deductedTotal
    totals.plannedSubtotal = totals.plannedDiscountedSubtotal + deductedTotal

    totals.total = totals.subtotal
    totals.netToPay = totals.subtotal

    // Applies total deduced Invoices
    if (state.downPaymentsDeduction === 'subtotal' && (state.subtype === DOCUMENT_SUBTYPE_FINAL || state.detailed)) {
      for (let deductedDocument of deductedDocuments.filter(
        (el) => el.deduction === 'subtotal' && el.alreadyDeducted < el.deductedInvoice.subtotal
      )) {
        deductedDocument.deducted = deductedDocument.deductedInvoice.subtotal
        totals.subtotal -= deductedDocument.deductedInvoice.subtotal
        totals.netToPay -= deductedDocument.deductedInvoice.subtotal
      }
    }

    let adjustmentRatio = roundToSix(totals.plannedSubtotal / totals.plannedGrossSubtotal)
    if (isNaN(adjustmentRatio)) {
      adjustmentRatio = 1
    }

    for (const data of lines) {
      const { lineTotal, ...line } = data
      const total = getLineTotal2.value(line.key, adjustmentRatio)

      // Sets Additional lines taxes
      if (!line.initial || line.additional) {
        if (lineTotal.subtotal > 0) {
          totals.additionalSubtotal += Currency.round(total.subtotal)
        } else {
          totals.depreciationSubtotal += Currency.round(Math.abs(total.subtotal))
        }

        if (Currency.round(line.quoteQuantity * line.sellPrice) > 0) {
          totals.additionalQuoted += Currency.round(line.quoteQuantity * line.sellPrice)
        } else {
          totals.depreciationQuoted += Math.abs(Currency.round(line.quoteQuantity * line.sellPrice))
        }
      }
    }

    totals.taxTotal = 0

    totals.taxAmounts = {}
    totals.additionalTaxAmounts = {}
  }

  const calculateTotalsWithTax = (state, lines, totals, deductedDocuments) => {
    for (const data of lines) {
      const { lineTotal, ...line } = data

      /*
      let ancestors = findAncestors(state, line.key)

     // TODO replace with loop on ancestors to check for optional
      let parentKey = Object.keys(state.childTree).find((key) => _get(state.childTree, key, []).includes(line.key))
      if (parentKey) {
        let parent = state.lines.find((el) => el.key === parentKey)
        if (parent && parent.optional) continue
      }*/

      const ancestors = getAncestors.value(line.key)
      if (ancestors.length && ancestors.some((el) => el.optional === true)) continue

      if (line.optional) continue
      if (lineTotal === undefined) continue

      // Total lines before discounts, deductions and taxes
      totals.grossSubtotal += Currency.round(lineTotal.subtotal)
      totals.plannedGrossSubtotal += Currency.round(lineTotal.plannedSubtotal)

      if (!_has(line, 'tax.id')) continue

      // Sets details for each tax
      if (!_has(totals.taxAmounts, line.tax.id)) {
        totals.taxAmounts[line.tax.id] = {
          acronym: line.tax.acronym,
          rate: line.tax.rate,
          total: 0,
          amount: 0,
          plannedTotal: 0,
          plannedAmount: 0,
          plannedDiscount: 0,
          discount: 0,
          deducted: 0,
          quoted: 0,
          quotedAmount: 0,
        }
      }

      totals.taxAmounts[line.tax.id].amount += Currency.round(lineTotal.taxAmount)
      totals.taxAmounts[line.tax.id].total += Currency.round(lineTotal.subtotal)

      totals.taxAmounts[line.tax.id].plannedAmount += Currency.round(lineTotal.plannedTaxAmount)
      totals.taxAmounts[line.tax.id].plannedTotal += Currency.round(lineTotal.plannedSubtotal)

      // Sets Additional lines taxes
      // Moved at bottom

      // Sets taxes subtotals
      for (const [taxId, taxAmount] of Object.entries(totals.taxAmounts)) {
        const taxRate = taxAmount.rate / 10000
        totals.taxAmounts[taxId].amount =
          state.roundingMethod === ROUNDING_METHOD_TOTAL ? Currency.round(taxAmount.total * taxRate) : taxAmount.amount

        totals.taxAmounts[taxId].plannedAmount =
          state.roundingMethod === ROUNDING_METHOD_TOTAL
            ? Currency.round(taxAmount.plannedTotal * taxRate)
            : taxAmount.plannedAmount
      }
    }

    // Applies AMOUNT discount
    if (state.amountOff && state.amountOff > 0) {
      for (const [taxId, taxAmount] of Object.entries(totals.taxAmounts)) {
        const taxRate = taxAmount.rate / 10000
        const ratio = roundToSix(taxAmount.total / totals.grossSubtotal)
        let discount = Currency.round(state.amountOff * ratio)
        if (isNaN(discount)) discount = 0

        totals.discountTotal += discount
        totals.taxAmounts[taxId].discount = discount

        totals.taxAmounts[taxId].total -= discount
        totals.taxAmounts[taxId].amount = Currency.round(totals.taxAmounts[taxId].total * taxRate)

        totals.taxAmounts[taxId].plannedTotal -= discount
        totals.taxAmounts[taxId].plannedAmount = Currency.round(totals.taxAmounts[taxId].plannedTotal * taxRate)
      }
    }

    // Applies PERCENT discount
    if (state.percentOff && state.percentOff > 0) {
      for (const [taxId, taxAmount] of Object.entries(totals.taxAmounts)) {
        const taxRate = taxAmount.rate / 10000

        const discount = Currency.round(taxAmount.total * (state.percentOff / 100))
        const plannedDiscount = Currency.round(taxAmount.plannedTotal * (state.percentOff / 100))

        totals.discountTotal += discount
        totals.taxAmounts[taxId].discount = discount
        totals.taxAmounts[taxId].plannedDiscount = plannedDiscount

        totals.taxAmounts[taxId].total -= discount
        totals.taxAmounts[taxId].amount = Currency.round(totals.taxAmounts[taxId].total * taxRate)

        totals.taxAmounts[taxId].plannedTotal -= plannedDiscount
        totals.taxAmounts[taxId].plannedAmount = Currency.round(totals.taxAmounts[taxId].plannedTotal * taxRate)
      }
    }

    totals.discountedSubtotal = totals.grossSubtotal - totals.discountTotal
    totals.plannedDiscountedSubtotal = totals.plannedGrossSubtotal - totals.discountTotal

    totals.salesSubtotal = totals.grossSubtotal - totals.discountTotal

    // Applies SUBTOTAL deductions
    if (totals.discountedSubtotal > 0 && Object.keys(state.subtotalDeductions).length > 0) {
      const previousTaxAmounts = _cloneDeep(totals.taxAmounts)
      for (const key of Object.keys(state.subtotalDeductions)) {
        for (const [taxId, taxAmount] of Object.entries(totals.taxAmounts)) {
          const taxRate = taxAmount.rate / 10000
          const deduction = _cloneDeep(state.subtotalDeductions[key])
          let deductionAmount = 0

          if (deduction.amountType === AMOUNT_TYPE_SUBTOTAL) {
            if (totals.discountedSubtotal > 0) {
              const ratio = roundToSix(previousTaxAmounts[taxId].total / totals.discountedSubtotal)
              deductionAmount = Currency.round(deduction.amount * ratio)
            }
          } else {
            deductionAmount = Currency.round(previousTaxAmounts[taxId].total * (deduction.amount / 100))
          }

          if (['minus', undefined].includes(deduction.direction)) {
            deductionAmount = Math.abs(deductionAmount) * -1
          }

          totals.taxAmounts[taxId].total += deductionAmount
          totals.taxAmounts[taxId].amount = Currency.round(totals.taxAmounts[taxId].total * taxRate)

          totals.salesSubtotal += deductionAmount
        }
      }
    }

    if (totals.plannedDiscountedSubtotal > 0 && Object.keys(state.subtotalDeductions).length > 0) {
      const previousTaxAmounts = _cloneDeep(totals.taxAmounts)
      for (const key of Object.keys(state.subtotalDeductions)) {
        for (const [taxId, taxAmount] of Object.entries(totals.taxAmounts)) {
          const taxRate = taxAmount.rate / 10000
          const deduction = _cloneDeep(state.subtotalDeductions[key])
          let deductionAmount = 0

          if (deduction.amountType === AMOUNT_TYPE_SUBTOTAL) {
            if (totals.plannedDiscountedSubtotal > 0) {
              const ratio = roundToSix(previousTaxAmounts[taxId].total / totals.plannedDiscountedSubtotal)
              deductionAmount = Currency.round(deduction.amount * ratio)
            }
          } else {
            deductionAmount = Currency.round(previousTaxAmounts[taxId].plannedTotal * (deduction.amount / 100))
          }

          if (['minus', undefined].includes(deduction.direction)) {
            deductionAmount = Math.abs(deductionAmount) * -1
          }

          totals.taxAmounts[taxId].plannedTotal += deductionAmount
          totals.taxAmounts[taxId].plannedAmount = Currency.round(totals.taxAmounts[taxId].plannedTotal * taxRate)
        }
      }
    }

    // Applies SUBTOTAL deduced Invoices
    if (state.subtype === DOCUMENT_SUBTYPE_FINAL || state.detailed) {
      for (const deductedDocument of deductedDocuments.filter(
        (el) => el.deduction === 'subtotal' && el.alreadyDeducted < el.deductedInvoice.total
      )) {
        for (const [taxId, taxAmount] of Object.entries(deductedDocument.deductedInvoice.taxAmounts)) {
          // Adds deducted invoice tax if missing from current invoice
          // Happens if user changes a product tax rate on final the invoice : that's dumb, should not happen
          if (!_has(totals.taxAmounts, taxId)) {
            const currentTax = _get(company.value, 'taxes', []).find((el) => el.id === taxId)
            if (currentTax) {
              totals.taxAmounts[taxId] = {
                acronym: currentTax.acronym,
                rate: currentTax.rate,
                total: 0,
                totalSellPrice: 0,
                amount: 0,
                plannedTotal: 0,
                plannedAmount: 0,
                discount: 0,
                deducted: 0,
              }
              totals.taxAmounts[taxId].deducted += taxAmount['total']
              totals.taxAmounts[taxId].total -= taxAmount['total']
              totals.taxAmounts[taxId].amount -= taxAmount['amount']
              totals.taxAmounts[taxId].plannedTotal -= taxAmount['plannedTotal']
              totals.taxAmounts[taxId].plannedAmount -= taxAmount['plannedAmount']
            }
          } else {
            totals.taxAmounts[taxId].deducted += taxAmount['total']
            totals.taxAmounts[taxId].total -= taxAmount['total']
            totals.taxAmounts[taxId].amount -= taxAmount['amount']
            totals.taxAmounts[taxId].plannedTotal -= taxAmount['plannedTotal']
            totals.taxAmounts[taxId].plannedAmount -= taxAmount['plannedAmount']
          }
        }
        deductedDocument.deducted = deductedDocument.deductedInvoice.subtotal
      }
    }

    // Updates totals
    for (let [taxId, taxAmount] of Object.entries(totals.taxAmounts)) {
      // Re-round taxAmount if using method total
      if (state.roundingMethod === ROUNDING_METHOD_TOTAL) {
        const taxRate = taxAmount.rate / 10000
        taxAmount.amount = Currency.round(taxAmount.total * taxRate)
        taxAmount.plannedAmount = Currency.round(taxAmount.plannedTotal * taxRate)
      }

      totals.subtotal += taxAmount['total']
      totals.plannedSubtotal += taxAmount['plannedTotal']
      totals.total += taxAmount['total'] + taxAmount['amount']

      totals.plannedTotal += taxAmount['plannedTotal'] + taxAmount['plannedAmount']
      totals.taxTotal += taxAmount['amount']
      totals.plannedTaxTotal += taxAmount['plannedAmount']
    }

    let adjustmentRatio = roundToSix(totals.plannedSubtotal / totals.plannedGrossSubtotal)
    if (isNaN(adjustmentRatio)) {
      adjustmentRatio = 1
    }

    //let adjustmentRatio = 1

    //console.log(adjustmentRatio)

    // Sets Additional lines taxes
    for (const data of lines) {
      const { lineTotal, ...line } = data

      // used to get the adjusted line total (not used anymore)
      const total = getLineTotal2.value(line.key, adjustmentRatio)

      if (!line.initial || line.additional) {
        if (!_has(totals.additionalTaxAmounts, line.tax.id)) {
          totals.additionalTaxAmounts[line.tax.id] = {
            acronym: line.tax.acronym,
            rate: line.tax.rate,
            total: 0,
            totalSellPrice: 0,
            amount: 0,
            depreciationAmount: 0,
            depreciationTotal: 0,
            discount: 0,
            deducted: 0,
            additionalQuoted: 0,
            additionalQuotedAmount: 0,
            depreciationQuoted: 0,
            depreciationQuotedAmount: 0,
          }
        }

        totals.additionalTaxAmounts[line.tax.id].totalSellPrice += Currency.round(total.subtotal)

        const rate = line.tax.rate / 10000
        const quoted = Currency.round(line.quoteQuantity * line.sellPrice) // * adjustmentRatio

        if (quoted > 0) {
          totals.additionalTaxAmounts[line.tax.id].amount += Currency.round(total.taxAmount)
          totals.additionalTaxAmounts[line.tax.id].total += Currency.round(total.subtotal)

          totals.additionalTaxAmounts[line.tax.id].additionalQuoted += quoted
          totals.additionalTaxAmounts[line.tax.id].additionalQuotedAmount += Currency.round(rate * quoted)
        } else {
          totals.additionalTaxAmounts[line.tax.id].depreciationAmount += Math.abs(Currency.round(total.taxAmount))
          totals.additionalTaxAmounts[line.tax.id].depreciationTotal += Math.abs(Currency.round(total.subtotal))

          totals.additionalTaxAmounts[line.tax.id].depreciationQuoted += Math.abs(quoted)
          totals.additionalTaxAmounts[line.tax.id].depreciationQuotedAmount += Math.abs(Currency.round(rate * quoted))
        }
      }
    }

    if (state.subtype === DOCUMENT_SUBTYPE_SITUATION) {
      for (const [taxId, additionalTaxAmount] of Object.entries(totals.additionalTaxAmounts)) {
        totals.additionalSubtotal += additionalTaxAmount['total']
        totals.additionalTotal += additionalTaxAmount['total'] + additionalTaxAmount['amount']

        totals.additionalQuoted += additionalTaxAmount['additionalQuoted']
        totals.additionalQuotedAmount += additionalTaxAmount['additionalQuotedAmount']

        totals.depreciationSubtotal += additionalTaxAmount['depreciationTotal']
        totals.depreciationTotal += additionalTaxAmount['depreciationTotal'] + additionalTaxAmount['depreciationAmount']

        totals.depreciationQuoted += additionalTaxAmount['depreciationQuoted']
        totals.depreciationQuotedAmount += additionalTaxAmount['depreciationQuotedAmount']
      }
    }

    totals.netToPay = totals.total
  }

  const documentTotal = ref({
    totalBuyPrice: 0,
    margin: 0,
    grossMargin: 0,
    marginRate: 0,
    grossMarginRate: 0,
    markupRate: 0,
    overhead: 0,
    profit: 0,
    profitRate: 0,
    workHours: 0,
    taxAmounts: {},
    taxTotal: 0,
    discountTotal: 0,
    discountedSubtotal: 0,
    salesSubtotal: 0,
    grossSubtotal: 0,
    subtotal: 0,
    total: 0,
    netToPay: 0,
    additionalTaxAmounts: {},
    additionalSubtotal: 0,
    additionalTotal: 0,
    additionalQuoted: 0,
    additionalQuotedAmount: 0,
    depreciationSubtotal: 0,
    depreciationTotal: 0,
    depreciationQuoted: 0,
    depreciationQuotedAmount: 0,
    holdbackAmount: 0,
    deductedDocuments: [],
  })

  watchEffect(() => {
    const lineTotals = getLineTotal.value

    let margins = {
      totalBuyPrice: 0,
      margin: 0,
      grossMargin: 0,
      marginRate: 0,
      grossMarginRate: 0,
      markupRate: 0,
      overhead: 0,
      profit: 0,
      profitRate: 0,
    }

    let totals = {
      workHours: 0,
      taxAmounts: {},
      taxTotal: 0,
      discountTotal: 0,
      discountedSubtotal: 0,
      salesSubtotal: 0,
      grossSubtotal: 0,
      subtotal: 0,
      total: 0,
      plannedSubtotal: 0,
      plannedGrossSubtotal: 0,
      plannedDiscountedSubtotal: 0,
      plannedDiscountTotal: 0,
      plannedTotal: 0,
      plannedTaxTotal: 0,
      netToPay: 0,
      additionalTaxAmounts: {},
      additionalSubtotal: 0,
      additionalTotal: 0,
      additionalQuoted: 0,
      additionalQuotedAmount: 0,
      depreciationSubtotal: 0,
      depreciationTotal: 0,
      depreciationQuoted: 0,
      depreciationQuotedAmount: 0,
      holdbackAmount: 0,
    }

    let deductedDocuments = _cloneDeep(state.value.deductedDocuments).filter(
      (el) =>
        [DOCUMENT_SUBTYPE_DOWN_PAYMENT, DOCUMENT_SUBTYPE_SITUATION].includes(el.deductedInvoice.subtype) &&
        el.deductedInvoice.status !== DOCUMENT_STATUS_CANCELLED
    )

    const lines = []

    for (const line of _cloneDeep(state.value.lines).filter(
      (el) =>
        ![LINE_TYPE_GROUP, LINE_TYPE_SUBTOTAL, LINE_TYPE_TEXT, LINE_TYPE_PAGE_BREAK, LINE_TYPE_LINE_BREAK].includes(
          el.type
        )
    )) {
      const lineTotal = lineTotals[line.key]

      // Sets margins

      if (!line.optional && lineTotal) {
        const ancestors = getAncestors.value(line.key)
        if (!ancestors.some((el) => el.optional === true)) {
          if (line.type !== LINE_TYPE_GROUP) {
            margins.margin += Currency.round(line.quantity * lineTotal.margin)
            margins.totalBuyPrice += Currency.round(line.quantity * lineTotal.buyPrice)
          }

          if (line.type === LINE_TYPE_PRODUCT && line.productType === PRODUCT_TYPE_WORKFORCE) {
            let hours = countHours(line)
            if (Number.isFinite(hours)) totals.workHours += hours
          }

          if (line.productType === PRODUCT_TYPE_WORK_DETAILED && line.supplies) {
            for (const supply of line.supplies) {
              if (supply.element && supply.element.type === PRODUCT_TYPE_WORKFORCE) {
                let hours = countHours(supply.element, supply.quantity * line.quantity)
                if (Number.isFinite(hours)) totals.workHours += hours
              }
            }
          }
        }
      }

      // Merges lineTotal in line, so we have it in calculateTotals methods without calling getLineTotal again
      lines.push({ ...line, lineTotal })
    }

    // Calculates totals
    if (state.value.taxable && !state.value.reverseCharge) {
      calculateTotalsWithTax(state.value, lines, totals, deductedDocuments)
    } else {
      calculateTotalsWithoutTax(state.value, lines, totals, deductedDocuments)
    }

    // Sets margins
    margins.grossMargin = totals.salesSubtotal - margins.totalBuyPrice
    margins.grossMarginRate = (margins.grossMargin / margins.totalBuyPrice) * 100
    margins.marginRate = (margins.grossMargin / totals.discountedSubtotal) * 100
    margins.markupRate = (margins.grossMargin / margins.totalBuyPrice) * 100

    margins.overhead = Currency.round(margins.totalBuyPrice * (company.value.defaultCoefficient / 100) * 100) / 100
    margins.profit = totals.salesSubtotal - (margins.totalBuyPrice + margins.overhead)

    margins.profitRate = (margins.profit / totals.salesSubtotal) * 100

    if (state.value.taxable && !state.value.reverseCharge) {
      totals.holdbackAmount = Math.round((totals.total * state.value.holdback) / 100)
    } else {
      totals.holdbackAmount = Math.round((totals.subtotal * state.value.holdback) / 100)
    }

    // Deduces holdbacks from Invoice
    if (state.value.holdback > 0 && state.value.objectType !== QUOTE) {
      totals.netToPay -= totals.holdbackAmount
    }

    // Total deductions
    let totalDeductions = _cloneDeep(state.value.totalDeductions)

    if (Object.keys(totalDeductions).length > 0) {
      for (const deduction of Object.values(totalDeductions)) {
        let amount = getDeductionValue(totals.total, deduction)
        if (['minus', undefined].includes(deduction.direction)) amount = Math.abs(amount) * -1
        totals.netToPay += amount
      }
    }

    // Applies TOTAL deduced Invoices
    if (
      state.value.downPaymentsDeduction === 'total' &&
      (state.value.subtype === DOCUMENT_SUBTYPE_FINAL || state.value.detailed)
    ) {
      for (let deductedDocument of deductedDocuments.filter(
        (el) => el.deduction === 'total' && el.alreadyDeducted < el.deductedInvoice.total
      )) {
        let maxDeduction = deductedDocument.deductedInvoice.total - deductedDocument.alreadyDeducted
        let toDeduct_total = deductedDocument.total

        if (state.value.downPaymentsProrated === true) {
          if (isSituationComplete.value) {
            // Use the exact remaining amount if the document is fully deducted in the current situation
            toDeduct_total = deductedDocument.deductedInvoice.total - deductedDocument.alreadyDeducted
          } else {
            const ratio = totals.total / state.value.quote.total
            toDeduct_total = Currency.round(deductedDocument.deductedInvoice.total * ratio)
          }

          // We can't deduct more than invoice total - already deducted
          deductedDocument.deducted = toDeduct_total <= maxDeduction ? toDeduct_total : maxDeduction
        } else {
          deductedDocument.deducted = deductedDocument.deductedInvoice.total
        }

        totals.netToPay -= toDeduct_total <= maxDeduction ? toDeduct_total : maxDeduction
      }
    }

    documentTotal.value = { ...totals, ...margins, deductedDocuments }
  })

  return documentTotal
}

export function useDetailsMargins(document) {
  const { company } = useUser()
  const { getUnitById } = useUnit()
  const Currency = useCurrency()

  const defaultCoefficient = computed(() => _get(company.value, 'defaultCoefficient', 0))

  return computed(() => {
    let margins = {
      totalBuyPrice: 0,
      margin: 0,
      grossMargin: 0,
      marginRate: 0,
      grossMarginRate: 0,
      markupRate: 0,
      overhead: 0,
      profit: 0,
      profitRate: 0,
    }

    let totals = { workHours: 0 }

    let flatLines = []

    const flattenLines = (lines, hasOptionalAncestor = false) => {
      for (const line of lines) {
        //console.log(line)
        const { lines: childLines, ...data } = line
        if (hasOptionalAncestor) data.optional = true
        flatLines.push(data)
        if (Array.isArray(childLines)) {
          flattenLines(childLines, hasOptionalAncestor || data.optional)
        }
      }
    }

    flattenLines(document.value.lines, false)

    for (const line of flatLines.filter(
      (el) => ![LINE_TYPE_SUBTOTAL, LINE_TYPE_TEXT, LINE_TYPE_PAGE_BREAK, LINE_TYPE_LINE_BREAK].includes(el.type)
    )) {
      let margin = 0

      if (!line.optional) {
        if (line.buyPrice) {
          let totalCost =
            defaultCoefficient.value > 0
              ? line.buyPrice + (line.buyPrice * defaultCoefficient.value) / 100
              : line.buyPrice
          margin = line.sellPrice - totalCost
        } else {
          margin = line.sellPrice
        }

        // Sets margins
        if (line.type !== LINE_TYPE_GROUP) {
          margins.margin += Currency.round(line.quantity * margin)
          margins.totalBuyPrice += Currency.round(line.quantity * line.buyPrice)
        }

        if (line.type === LINE_TYPE_PRODUCT && line.productType === PRODUCT_TYPE_WORKFORCE) {
          let hours = countHours(line)
          if (Number.isFinite(hours)) totals.workHours += hours
        }

        if (line.productType === PRODUCT_TYPE_WORK_DETAILED && line.supplies) {
          for (const supply of line.supplies) {
            if (supply.element && supply.element.type === PRODUCT_TYPE_WORKFORCE) {
              let element = { ...supply.element }
              element.unit = getUnitById(element.unit)

              let hours = countHours(element, supply.quantity * line.quantity)
              if (Number.isFinite(hours)) totals.workHours += hours
            }
          }
        }
      }
    }

    // Sets margins
    margins.grossMargin = document.value.subtotal - margins.totalBuyPrice
    margins.grossMarginRate = (margins.grossMargin / margins.totalBuyPrice) * 100
    margins.marginRate = (margins.grossMargin / document.value.discountedSubtotal) * 100
    margins.markupRate = (margins.grossMargin / margins.totalBuyPrice) * 100

    margins.overhead = Currency.round(margins.totalBuyPrice * (defaultCoefficient.value / 100) * 100) / 100
    margins.profit = document.value.subtotal - (margins.totalBuyPrice + margins.overhead)

    margins.profitRate = (margins.profit / document.value.subtotal) * 100

    return { ...margins, ...totals }
  })
}

export const useLineIndex = (key) => {
  const { getNumberingClass } = useDocumentBuilderColumns()
  const { getIndexes } = useGetters(['getIndexes'])

  const index = computed(() => getIndexes.value[key])

  const indexLength = computed(() => (index.value ? index.value.toString().split('.').length : 0))
  const numberingClass = computed(() => getNumberingClass(indexLength))

  const nextIndex = computed(() => _get(getIndexes.value, `${key}_next`, null))

  return { index, nextIndex, indexLength, numberingClass }
}
