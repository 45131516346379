import { computed, readonly, ref, useRoute, useRouter } from '@nuxtjs/composition-api'
import { cloneDeep as _cloneDeep, get as _get, has as _has } from 'lodash-es'
import acl from '@/config/acl'
import { availableCurrencies, getCountryLocale } from '@/config/locales'
import { differenceInCalendarDays, format, isBefore, isSameYear } from 'date-fns'
import { fr as dateFnsLocale } from 'date-fns/locale'

import TimeAgo from 'javascript-time-ago'
import fr from 'javascript-time-ago/locale/fr'
import { AMOUNT_TYPE_AMOUNT, AMOUNT_TYPE_PERCENT, AMOUNT_TYPE_SUBTOTAL, AMOUNT_TYPE_TOTAL } from '@/constants/documents'
import { formatTax } from '@/utils/documents'
import { withQuery } from 'ufo'
import { sha256 } from 'ohash'
import { ACCOUNTING_MODE_DISABLED } from '@/constants/companies'

TimeAgo.addDefaultLocale(fr)
const timeAgo = new TimeAgo('fr-FR')

export default (ctx, inject) => {
  const user = ref({})
  const company = ref({})
  const init = ref(false)
  const mustLogin = ref(false)
  const loggedIn = ref(false)

  const lastNotificationsCall = ref(Date.now())

  const subscription = computed(() => _get(company.value, 'subscriptions.app', null))
  const products = computed(() => _get(company.value, 'subscriptions', null))

  const login = (credentials) => {
    const switch_company = _get(ctx.route, 'query.switch_company', undefined)

    return new Promise(async (resolve, reject) => {
      try {
        await ctx.app.$axios.$post('/authentication_token', credentials)
        const { data: user } = await ctx.app.$axios.$get('/api/users/me', {
          params: { _expand: ['signature', 'credits'], switch_company },
          progress: false,
        })

        if (user.company === null) {
          await ctx.app.router.push('/invites')
        } else {
          const { data: companies } = await ctx.app.$axios.$get('/api/users/me/companies', {
            params: {},
            progress: false,
          })

          const { data: units } = await ctx.app.$axios.$get('/api/units', {
            params: { _limit: 100 },
            progress: false,
          })

          let planItems = undefined

          if (user.company.accountingMode !== ACCOUNTING_MODE_DISABLED) {
            const { data: getPlanItems } = await ctx.app.$axios.$get('/api/plan_items/simple', {
              params: { _limit: 100 },
              progress: false,
            })

            planItems = getPlanItems
          }

          const { data: subscriptions } = await ctx.app.$axios.$get('api/billing/subscriptions', {
            params: { _expand: ['plans'] },
            progress: false,
          })

          ctx.store.commit('documents/loadUnits', units)
          if (planItems) ctx.store.commit('documents/loadPlanItems', planItems)

          initUserData(user, { units, planItems, subscriptions, companies })
          resolve()
        }
      } catch (err) {
        reject(err)
      }
    })
  }

  const getNotifications = async () => {
    try {
      if (Date.now() - lastNotificationsCall.value > 60000) {
        const { data } = await ctx.app.$axios.$get('api/users/me/notifications')
        if (data.notificationsCount > 0) user.value.notificationsCount = data.notificationsCount
        if (data.newsCount > 0) user.value.newsCount = data.newsCount
        lastNotificationsCall.value = Date.now()
      }
    } catch (err) {}
  }

  const logout = async () => {
    await ctx.app.$axios.$post('api/users/logout')
    loggedIn.value = false
    init.value = false
  }

  const setMustLogin = (bool) => {
    mustLogin.value = bool
  }

  const getLogin = async () => {
    const switch_company = _get(ctx.route, 'query.switch_company', undefined)

    try {
      const cleared = localStorage.getItem('appvizer_cleared')
      if (cleared !== '1') {
        localStorage.removeItem('appvizer_data')
        localStorage.setItem('appvizer_cleared', 1)
      }
    } catch (err) {}

    try {
      const { data: user } = await ctx.app.$axios.$get('/api/users/me', {
        params: { _expand: ['signature', 'credits'], switch_company },
        progress: false,
        timeout: 30000,
        retry: true,
      })

      if (user.company === null) {
        ctx.app.router.push('/invites')
      } else {
        const { data: companies } = await ctx.app.$axios.$get('/api/users/me/companies', {
          params: {},
          progress: false,
        })

        const { data: units } = await ctx.app.$axios.$get('/api/units', {
          params: { _limit: 50 },
          progress: false,
          retry: true,
        })

        let planItems = undefined
        if (user.company.accountingMode !== ACCOUNTING_MODE_DISABLED) {
          const { data: getPlanItems } = await ctx.app.$axios.$get('/api/plan_items/simple', {
            params: { _limit: 100 },
            progress: false,
          })
          planItems = getPlanItems
        }

        const { data: subscriptions } = await ctx.app.$axios.$get('api/billing/subscriptions', {
          params: { _expand: ['plans'] },
          progress: false,
          retry: true,
        })

        ctx.store.commit('documents/loadUnits', units)
        if (planItems) ctx.store.commit('documents/loadPlanItems', planItems)
        initUserData(user, { units, planItems, subscriptions, companies })

        try {
          const impersonate = _get(ctx.route, 'query._impersonate', null)
          if (impersonate !== null) {
            ctx.store.commit('impersonate', impersonate === '_exit' ? null : impersonate.replace(' ', '+'))
          }
        } catch (err) {
          console.log(err)
        }

        if (window.$crisp && typeof window.$crisp.push === 'function') {
          window.$crisp.push(['set', 'user:email', [user.email]])

          if (_get(user, 'phone', null)) {
            window.$crisp.push(['set', 'user:phone', [user.phone]])
          }

          window.$crisp.push(['set', 'user:company', _get(user, 'company.name', null)])

          if (`${_get(user, 'firstName', '')} ${_get(user, 'lastName', '')}`.trim() !== '') {
            window.$crisp.push([
              'set',
              'user:nickname',
              [`${_get(user, 'firstName', '')} ${_get(user, 'lastName', '')}`],
            ])
          }

          /*const isActive = _get(subscriptions, 'app.status', null) === 'active'
          const isPaid = _get(subscriptions, 'app.plan.name', 'Starter') !== 'Starter'

          if (typeof window.$crisp.is === 'function' && window.$crisp.is('chat:closed')) {
            if (isActive) window.$crisp.push(['do', 'chat:hide'])
            window.$crisp.push(['do', 'chat:show'])
          }*/
        }

        if (window.clarity) {
          window.clarity('identify', user.id)
          window.clarity('set', 'name', `${user.firstName} ${user.lastName}`)
          window.clarity('set', 'email', user.email)
        }

        if (_get(ctx, '$sentry')) {
          ctx.$sentry.setUser({
            id: user.id,
          })
        }

        if (window.dataLayer) {
          window.dataLayer.push({
            event: 'login',
            user_id: user.id,
            user_data: {
              sha256_email_address: [sha256(user.email)],
            },
          })
        }
      }
    } catch (err) {
      if (err.code === 'ECONNABORTED') {
        ctx.app.router.push('/maintenance')
      }
      loggedIn.value = false
      setMustLogin(true)
    }
  }

  const initUserData = (data, extraData = {}) => {
    Object.entries(extraData).forEach(([k, v]) => {
      if (['companies'].includes(k)) {
        data[k] = v
      } else {
        data.company[k] = v
      }
    })

    company.value = Object.entries(data.company).reduce((obj, [key, val]) => {
      if (['address', 'insuranceAddress'].includes(key) && (val === null || Array.isArray(val))) {
        val = {}
      }

      obj[key] = val

      return obj
    }, {})

    if (company.value.defaultTax === null) {
      company.value.defaultTax = _get(_cloneDeep(company.value), 'taxes', [
        {
          id: null,
          rate: 0,
        },
      ])
        .sort((a, b) => (a.rate > b.rate ? -1 : 1))
        .shift()
    }

    const { company: _, ...userData } = data
    user.value = userData

    setMustLogin(false)
    loggedIn.value = true
    init.value = true
  }

  const updateUser = async (params, persist = false) => {
    const update = { ...user.value }
    const fields = {}
    for (const [key, val] of Object.entries(params)) {
      update[key] = val
      fields[key] = val
    }

    if (window.$crisp !== undefined) {
      if (`${_get(user, 'firstName', '')} ${_get(user, 'lastName', '')}`.trim() !== '') {
        window.$crisp.push(['set', 'user:nickname', [`${_get(user, 'firstName', '')} ${_get(user, 'lastName', '')}`]])
      }
    }

    if (persist) {
      try {
        await ctx.app.$axios.$put('/api/users/me', fields)
      } catch (err) {}
    }

    user.value = update
  }

  const updateCompany = (params) => {
    const update = { ...company.value }
    for (const [key, val] of Object.entries(params)) {
      update[key] = val
    }
    company.value = update
  }

  const getTaxName = (taxId) => {
    let tax = _get(company.value, `taxes`, []).find((el) => el.id === taxId)
    if (tax) {
      return _get(tax, 'name', null)
    }
    return null
  }

  const hasPlan = (plan) => user.value.plan === plan

  const hasTopBar = () =>
    _get(company.value, 'flags', []).filter((el) =>
      [
        'starter_incomes_book',
        'webinar',
        'starter_bf2023_discount',
        'starter_bts2023_discount',
        'starter_bts2024_discount',
        'referral_discount',
      ].includes(el)
    ).length > 0

  const cap = (scope = 'plan', group = '') => {
    try {
      let path = _get(subscription.value, 'plan.slug', 'starter')

      if (scope === 'role') {
        path = _get(user.value, 'companyRole', 'ROLE_USER')
        if (path === 'ROLE_OWNER') return true
      }

      return _get(acl, `${scope}.${path}.${group}`, [])
    } catch (err) {
      return null
    }
  }

  const can = (scope = 'plan', feature = '') => {
    let matchCapability = false

    try {
      let object = feature.split('.')
      let action = object.pop()

      let trialUntil = _get(acl, `${scope}._rules.${feature}.trialUntil`, null)
      if (trialUntil && isBefore(new Date(), trialUntil)) {
        return 'trial'
      }

      let path = _get(subscription.value, 'plan.slug', 'starter')

      if (scope === 'role') {
        path = _get(user.value, 'companyRole', 'ROLE_USER')
        if (path === 'ROLE_OWNER') return true
      }

      matchCapability = _get(acl, `${scope}.${path}.${object.join('.')}`, []).includes(action)

      if (scope === 'role') {
        // Checks if global "documents" permission is granted
        if (
          !matchCapability &&
          ['quotes', 'invoices', 'credits', 'order_forms', 'supplier_invoice'].includes(_get(object, '0'))
        ) {
          object[0] = 'documents'
          matchCapability = _get(acl, `${scope}.${path}.${object.join('.')}`, []).includes(action)
        }
      }

      if (object[0] === '_route') {
        let routeName = _get(ctx.route, 'name', null)
        matchCapability = false
        for (const matchedRoute of _get(acl, `${scope}.${path}.${object.join('.')}`, [])) {
          if (routeName.includes(matchedRoute)) {
            matchCapability = true
          }
        }
      }
    } catch (err) {
      console.log(err)
    }

    return matchCapability
  }

  const getHome = () => {
    const role = _get(user.value, 'companyRole', 'ROLE_USER')
    const home = _get(acl, `role.${role}._home`, null)
    if (home) {
      return home
    }
    return ''
  }

  const handleRoute = (path, fallback = null) => {
    let basePath = path.split('.')
    basePath = basePath.shift()

    if (!can('role', `${basePath}.manage`) && !can('role', path)) {
      if (fallback !== null) ctx.redirect(`/${fallback}`)
      else if (getHome()) {
        ctx.redirect(withQuery(`/${getHome()}`, _get(ctx.route, 'query', {})))
      }
    }
  }

  const isRoot = () =>
    _get(user.value, 'email', '').includes('@costructor.co') &&
    _get(user.value, 'email', '') !== 'demo@costructor.co' &&
    _get(user.value, 'email', '') !== 'ghost@costructor.co'

  const requiredPlan = (feature) => {
    let object = feature.split('.')
    let action = object.pop()
    for (const plan of Object.keys(acl.plan)) {
      const capabilities = _get(acl.plan, `${plan}.${object.join('.')}`, null)

      if (Array.isArray(capabilities) && capabilities.includes(action)) {
        return plan
      }
    }
    return null
  }

  const getCurrencySymbol = (force = undefined) =>
    _get(
      availableCurrencies,
      `${(force ? force.toLowerCase() : _get(company.value, 'currency', 'eur')).toLowerCase()}.symbol`,
      '€'
    )

  inject('localized', {
    feature: (val) => {
      let availableFeatures = _get(
        {
          fr: ['noTaxableMessage'],
          gp: ['noTaxableMessage'],
          mq: ['noTaxableMessage'],
          re: ['noTaxableMessage'],
          gf: ['noTaxableMessage'],
          default: [],
        },
        _get(company, 'value.country', 'fr'),
        []
      )

      return availableFeatures.includes(val)
    },
    amountTypesOptions: (types, force = undefined) => {
      return types.reduce((obj, type) => {
        switch (type) {
          case AMOUNT_TYPE_PERCENT:
            obj[type] = '%'
            break
          case AMOUNT_TYPE_SUBTOTAL:
          case AMOUNT_TYPE_AMOUNT:
            obj[type] = `${getCurrencySymbol(force)} HT`
            break
          case AMOUNT_TYPE_TOTAL:
            obj[type] = `${getCurrencySymbol(force)} TTC`
            break
        }
        return obj
      }, {})
    },
  })

  inject('format', {
    currencySymbol: (force) => getCurrencySymbol(force),
    tax: (val) => formatTax(val),
    currency: (val, force = undefined, maximumFractionDigits = undefined) => {
      if (isNaN(val)) return '-'

      const currency = force || _get(company, 'value.currency', 'EUR')
      const country = _get(company, 'value.country', 'fr')
      const locale = getCountryLocale(country)
      //const userLocale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language

      return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: maximumFractionDigits, // Should be 0 or undefined
        maximumFractionDigits,
      }).format(val / 100)
    },
    currencyShort: (val, force = undefined, maximumFractionDigits = undefined) => {
      if (isNaN(val)) return '-'

      const currency = force || _get(company, 'value.currency', 'EUR')
      const country = _get(company, 'value.country', 'fr')
      const locale = getCountryLocale(country)
      //const userLocale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language

      let number = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: maximumFractionDigits, // Should be 0 or undefined
        maximumFractionDigits,
      }).format(val / 100)

      if (number.endsWith(',00')) {
        return number.replace(',00', '')
      }

      return number
    },
    number: (val, precision = 2) => {
      if (val === null) {
        val = 0
      }

      if (typeof val === 'number') {
        val = val.toFixed(precision)
      }

      let removePad = ',00'

      if (precision === 3) {
        removePad = ',000'
      }

      if (precision === 4) {
        removePad = ',0000'
      }

      if (precision === 5) {
        removePad = ',00000'
      }

      let number = Number(val).toString().replace('.', ',')

      if (number.endsWith(removePad)) {
        return number.replace(removePad, '')
      }

      return number
    },
    timeAgo: (val) => timeAgo.format(new Date(String(val))),
    humanDate: (val) => {
      if (!val) return '–'

      const date = new Date(String(val))
      if (isSameYear(date, new Date())) {
        return format(date, 'dd MMM', { locale: dateFnsLocale })
      }
      return format(date, 'dd MMM yyyy', { locale: dateFnsLocale })
    },
    humanDateTime: (val) => {
      if (!val) return '–'

      const date = new Date(String(val))
      if (isSameYear(date, new Date())) {
        return format(date, "dd MMM 'à' HH:mm", { locale: dateFnsLocale })
      }
      return format(date, "dd MMM yyyy 'à' HH:mm", { locale: dateFnsLocale })
    },
    longDate: (val) => (val ? format(new Date(String(val)), 'dd/MM/yyyy', { locale: dateFnsLocale }) : '–'),
    closeDate: (val) => {
      const now = new Date()
      const date = new Date(String(val))

      if (differenceInCalendarDays(now, date) > 0) {
        return format(date, "'Le' dd/MM 'à' HH:mm")
      }

      return format(date, "'à' HH:mm")
    },
  })

  inject('auth', {
    init: readonly(init),
    mustLogin: readonly(mustLogin),
    user: readonly(user),
    company: readonly(company),
    subscription: readonly(subscription),
    products: readonly(products),
    loggedIn: readonly(loggedIn),
    hasTopBar,
    can,
    cap,
    getHome,
    handleRoute,
    isRoot,
    requiredPlan,
    login,
    logout,
    hasPlan,
    updateUser,
    initUserData,
    setMustLogin,
    getNotifications,
    updateCompany,
    getLogin,
    getTaxName,
  })
}
