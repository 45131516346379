//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  computed,
  defineComponent,
  nextTick,
  reactive,
  ref,
  onMounted,
  useContext,
  useFetch,
  watch,
} from '@nuxtjs/composition-api'

import { watchDebounced } from '@vueuse/core'

import { get as _get, has as _has, isEqual as _isEqual, filter as _filter, isEmpty as _isEmpty } from 'lodash-es'
import useHighlight from '@/composables/highlight'
import { normalizeString } from '@/utils/normalizers'
import { useLoading } from '@/composables/loading'
import { nanoid } from 'nanoid/non-secure'

export default defineComponent({
  props: {
    value: {
      type: [String, Object],
      default: () => {},
    },
    resource: {
      type: String,
      default: '',
    },
    allowCreate: Boolean,
    hideResultsTitle: Boolean,
    noInteraction: Boolean,
    nullable: Boolean,
    filter: Boolean,
    hideOnSelect: {
      type: Boolean,
      default: true,
    },
    trans: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    createLabel: {
      type: String,
      default: null,
    },
    nameProperty: {
      type: String,
      default: 'name',
    },
    path: {
      type: String,
      default: '',
    },
    queryParams: {
      type: Object,
      default: () => {},
    },
    groupBy: {
      type: String,
      default: null,
    },
    groupByNameProp: {
      type: String,
      default: 'name',
    },
    fixedDropdown: Boolean,
    portal: {
      type: String,
      default: '',
    },
    hasOwnValue: {
      type: Boolean,
      default: true,
    },
    fillDefault: Boolean,
    exclude: {
      type: Array,
      default: () => [],
    },
    maxHeight: {
      type: Number,
      default: 200,
    },
    loading: Boolean,
    autoload: Boolean,
    autofocus: Boolean,
  },
  setup(props, { emit }) {
    const { app, $getRepository } = useContext()
    const { isLoading, setLoading } = useLoading()
    const $repository = $getRepository(props.resource)

    const id = nanoid()
    const dropdownPos = reactive({ x: 0, y: 0, width: 0 })
    const mobileInput = ref(null)
    const searchInput = ref(null)
    const searchContainer = ref(null)
    const scrollContainer = ref(null)
    const scrollTarget = ref(null)

    const init = ref(false)
    const endOfList = ref(false)
    const page = ref(1)
    const error = ref(null)
    const search = ref('')
    const showSearch = ref(false)
    const creating = ref(false)

    const result = ref(null)
    const results = ref([])

    const filterTree = (filter, list) => {
      return _filter(list, (item) => {
        if (
          !props.exclude.includes(item.id) &&
          normalizeString(item[props.nameProperty]).includes(normalizeString(filter))
        ) {
          return true
        } else if (item.children) {
          item.children = filterTree(filter, item.children)
          return !_isEmpty(item.children)
        }
      })
    }

    const filteredResults = computed(() => {
      return results.value ? (props.filter ? filterTree(search.value, results.value) : results.value) : []
    })

    const groupedResults = computed(() => {
      if (props.groupBy) {
        let grouped = results.value.reduce((obj, result) => {
          let groupKey = _get(result, props.groupBy, null)

          if (
            !props.exclude.includes(result.id) &&
            normalizeString(result[props.nameProperty]).includes(normalizeString(search.value))
          ) {
            if (Object.keys(obj).includes(String(groupKey))) {
              obj[groupKey].results.push(result)
            } else {
              obj[groupKey] = {
                name: app.i18n.t(
                  `${props.trans || props.resource}.label.groupBy.${props.groupBy}.${
                    _get(result, props.groupByNameProp, null) ? 'named' : 'empty'
                  }`,
                  { name: _get(result, props.groupByNameProp, null) }
                ),
                results: [result],
              }
            }
          }

          return obj
        }, {})

        return Object.entries(grouped)
          .sort((a, b) => {
            return a[0] === 'null' && b[0] !== 'null' ? 1 : -1
          })
          .reduce((r, [k, v]) => ({ ...r, [k]: v }), {})
      }
      return {}
    })

    const { fetch, fetchState } = useFetch(async () => {
      //console.log(force, showSearch.value, props.autoload)
      if ((showSearch.value || props.autoload) && !endOfList.value) {
        if (init.value === false) {
          setLoading(true, `dropdown_${id}`)
        }

        try {
          const { data, metadata } = await (props.path !== ''
            ? $repository.get(props.path, '', { ...props.queryParams, ...{ _page: page.value, _limit: 100 } })
            : $repository.list({ params: { ...props.queryParams, ...{ _page: page.value, _limit: 100 } } }))

          if (init.value) {
            results.value = [].concat(results.value, data)
          } else {
            results.value = data
            if (props.fillDefault) {
              selectResult(results.value[0])
            }
          }

          if (results.value.length >= metadata.items) {
            endOfList.value = true
          }

          init.value = true
          page.value += 1
        } catch (err) {
          console.log(err)
        } finally {
          setLoading(false, `dropdown_${id}`)
        }
      }
    })

    const handleScroll = async (event) => {
      event.stopPropagation()
      let { scrollTop, scrollHeight, clientHeight } = event.target

      if (!isLoading(`dropdown_loadMore_${id}`) && scrollHeight - clientHeight - 100 < scrollTop && !endOfList.value) {
        setLoading(true, `dropdown_loadMore_${id}`)
        await fetch()
        setLoading(false, `dropdown_loadMore_${id}`)
      }
    }

    const { highlight } = useHighlight()

    const invalidate = () => {
      creating.value = false
      error.value = 'must_select'
    }

    const injectItem = (item) => {
      results.value.unshift(item)
    }

    const displaySearch = () => {
      if (searchContainer.value) {
        const rect = searchContainer.value.getBoundingClientRect()
        dropdownPos.x = rect.x
        dropdownPos.y = rect.y + rect.height + 1
        dropdownPos.width = rect.width
      }

      showSearch.value = true
      //fetch()
    }

    const selectResult = (item) => {
      if (props.hasOwnValue) {
        result.value = item
      }

      if (props.hideOnSelect) {
        search.value = ''
        showSearch.value = false
      }

      error.value = null
      emit('select', item)
    }

    const isSelected = (current) => _get(result.value, 'id', null) === _get(current, 'id', undefined)

    const reload = async () => {
      page.value = 1
      endOfList.value = false
      init.value = false
      await fetch()
      search.value = ''
    }

    watch(
      () => props.value,
      (val) => {
        result.value = val
      }
    )

    watch(
      () => props.queryParams,
      async (newVal, oldVal) => {
        if (!_isEqual(newVal, oldVal)) {
          page.value = 1
          endOfList.value = false
          init.value = false
          await fetch()
        }
      }
    )

    watchDebounced(
      search,
      async (val) => {
        try {
          setLoading(true, `dropdown_${id}_search`)
          const { data, metadata } = await (props.path !== ''
            ? $repository.get(props.path, '', {
                ...props.queryParams,
                ...{ _page: 1, _search: val, _limit: 100 },
              })
            : $repository.list({
                params: { ...props.queryParams, ...{ _page: 1, _search: val, _limit: 100 } },
              }))

          results.value = [].concat(data)

          if (results.value.length >= metadata.items) {
            endOfList.value = true
          }
          page.value = 1
        } catch (err) {
        } finally {
          setLoading(false, `dropdown_${id}`)
          setLoading(false, `dropdown_${id}_search`)
        }
      },
      { debounce: 300, maxWait: 10000 }
    )

    watch(showSearch, async (val) => {
      emit('show', val)
      if (val) {
        await fetch()
        await nextTick()
        if (searchInput.value) searchInput.value.focus()
        if (mobileInput.value) mobileInput.value.focus()

        if (scrollContainer.value) {
          scrollContainer.value.scrollElement.addEventListener('scroll', handleScroll)
        }
      } else {
        scrollContainer.value.scrollElement.removeEventListener('scroll', handleScroll)
        if (props.nullable) {
          error.value = null
        } else {
          error.value = creating.value === false && search.value !== '' && result.value === null ? 'must_select' : null
        }
      }
    })

    onMounted(() => {
      result.value = props.value
      if (props.autofocus) {
        showSearch.value = true
      }
    })

    return {
      props,
      id,
      error,
      init,
      search,
      showSearch,
      creating,
      result,
      results,
      fetch,
      filteredResults,
      groupedResults,
      dropdownPos,
      mobileInput,
      searchInput,
      searchContainer,
      scrollContainer,
      scrollTarget,
      reload,
      isLoading,
      invalidate,
      injectItem,
      displaySearch,
      selectResult,
      isSelected,
      highlight,
    }
  },
})
