import { render, staticRenderFns } from "./InvoiceCreateModal.vue?vue&type=template&id=19f17d08&"
import script from "./InvoiceCreateModal.vue?vue&type=script&lang=js&"
export * from "./InvoiceCreateModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ModalCloseButton: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/layout/ModalCloseButton.vue').default,Tabs: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/Tabs.vue').default,Modal: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/layout/Modal.vue').default})
