var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{class:( _obj = {
    'bg-white w-full text-sm flex items-center border border-gray-300 rounded-md h-[38px]': !_vm.headless,
    'hover:border-gray-400': !_vm.headless && !_vm.disabled,
    'cursor-default': _vm.disabled
  }, _obj[_vm.classes] = _vm.headless === true, _obj ),attrs:{"type":"button"}},[_c(_vm.disabled ? 'div' : 'VDropdown',{tag:"component",staticClass:"w-full",attrs:{"positioning-disabled":_vm.disabled ? undefined : ['xs', 'sm'].includes(_vm.$mq),"skidding":_vm.disabled ? undefined : _vm._dateRange !== null ? 4 : _vm.date !== null && _vm.nullable ? 32 : 0,"placement":_vm.disabled ? undefined : 'bottom-end',"popper-class":_vm.disabled ? undefined : 'no-arrow !z-[111111]',"compute-transform-origin":""},scopedSlots:_vm._u([{key:"popper",fn:function(ref){
  var hide = ref.hide;
return [_c(['xs', 'sm'].includes(_vm.$mq) ? 'perfect-scrollbar' : 'div',{tag:"component",staticClass:"p-1 sm:p-0 w-full max-h-[80vh] rounded-lg"},[_c('V3DatePickerContainer',{attrs:{"hide":hide},on:{"updateDate":function($event){return _vm.$emit('updateDate', $event)},"updateDateRange":function($event){return _vm.$emit('updateDateRange', $event)}}})],1)]}}])},[_c('button',{staticClass:"w-full flex items-center text-gray-600",class:[_vm.headless ? 'px-2 py-1.5' : 'pl-3 py-2', _vm.disabled ? 'cursor-default' : ''],attrs:{"type":"button"}},[_c('span',{class:_vm.headless ? '' : 'mr-1'},[(_vm._isDateRange)?[(_vm._dateRange !== null)?_c('span',{staticClass:"whitespace-nowrap",domProps:{"innerHTML":_vm._s(_vm.dateRangeText)}}):_c('span',{staticClass:"text-gray-400"},[_vm._v(_vm._s(_vm.placeholder || 'jj/mm/aaaa'))])]:[(_vm.date && _vm.isValid(new Date(_vm.date)))?_c('span',{domProps:{"innerHTML":_vm._s(_vm.format(new Date(_vm.date), 'dd/MM/yyyy'))}}):_c('span',{staticClass:"text-gray-400"},[_vm._v(_vm._s(_vm.placeholder || 'jj/mm/aaaa'))])]],2),_vm._v(" "),(!_vm.headless && (!_vm.nullable || (_vm._isDateRange && !_vm._dateRange) || (!_vm._isDateRange && !_vm.date)))?_c('div',{staticClass:"ml-auto mr-1 w-6 h-6 text-base flex items-center justify-center text-gray-400"},[_c('fa-icon',{attrs:{"icon":['fal', 'calendar-day']}})],1):_vm._e()])]),_vm._v(" "),(_vm.nullable && ((_vm._isDateRange && _vm._dateRange !== null) || (!_vm._isDateRange && _vm.date !== null)))?_c('button',{staticClass:"px-1 flex items-center justify-center group ml-auto",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit(_vm._isDateRange ? 'updateDateRange' : 'updateDate', null)}}},[_c('div',{staticClass:"w-6 h-6 text-base flex items-center justify-center rounded-full text-gray-500 group-hover:bg-brand-50"},[_c('fa-icon',{attrs:{"icon":['far', 'xmark']}})],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }