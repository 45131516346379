//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed, defineComponent, onMounted, provide, ref, watch } from '@nuxtjs/composition-api'
import { endOfMonth, format, startOfDay, startOfMonth, isValid } from 'date-fns'
import { fr } from 'date-fns/locale'
import { listRanges } from '@/config/dates'
import { parseISO } from 'date-fns/fp'

export default defineComponent({
  props: {
    disabled: Boolean,
    isRange: Boolean,
    includeAll: Boolean,
    nullable: Boolean,
    headless: Boolean,
    dateRange: Object,
    classes: String,
    date: [String, Date],
    placeholder: String,
    minDate: [String, Date],
    maxDate: [String, Date],
  },
  setup(props, { emit }) {
    const _dateRange = ref(null)
    const _date = ref(null)

    const ranges = computed(() => {
      let ranges = { ...listRanges }
      if (props.includeAll) {
        ranges.all = [new Date('2022-01-01'), startOfDay(new Date())]
      }
      return ranges
    })

    watch(
      () => props.dateRange,
      (val) => {
        _dateRange.value = val
      }
    )

    watch(
      () => props.date,
      (val) => {
        if (typeof val === 'string') {
          val = parseISO(val)
        }
        _date.value = val
      }
    )

    onMounted(() => {
      if (_date.value === null && props.date) {
        let date = props.date

        if (typeof date === 'string') {
          date = new Date(props.date)
        }
        _date.value = date
      }

      if (props.isRange) {
        _dateRange.value = props.dateRange
      }
    })

    const dates = ref(_dateRange.value)

    const _isDateRange = computed(() => props.isRange || (_dateRange.value !== null && _dateRange.value !== undefined))

    const dateRangeText = computed(() => {
      return _dateRange.value
        ? `${format(_dateRange.value.start, 'dd/MM/yyyy')} - ${format(_dateRange.value.end, 'dd/MM/yyyy')}`
        : '&mdash;'
    })

    const _minDate = computed(() => (props.minDate ? new Date(props.minDate) : null))
    const _maxDate = computed(() => (props.maxDate ? new Date(props.maxDate) : null))

    provide('isDateRange', _isDateRange)
    provide('ranges', ranges)
    provide('date', _date)
    provide('dateRange', _dateRange)
    provide('minDate', _minDate)
    provide('maxDate', _maxDate)

    return {
      props,
      ranges,
      _dateRange,
      dates,
      _isDateRange,
      dateRangeText,

      isValid,
      format: (day, stringFormat) => format(day, stringFormat, { locale: fr }),
    }
  },
})
