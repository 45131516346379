//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get as _get, has as _has } from 'lodash-es'
import { defineComponent, ref, useRouter, useRoute, onMounted, watch } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    changeRoute: {
      type: Boolean,
      default: false,
    },
    hasBorder: {
      type: Boolean,
      default: false,
    },
    activeBorderClass: {
      type: String,
      default: '',
    },
    innerClass: {
      type: String,
      default: '',
    },
    spacingClass: {
      type: String,
      default: '',
    },
    trans: {
      type: String,
      default: '',
    },
    default: {
      default: null,
    },
    itemClass: {
      type: String,
      default: '',
    },
    value: {
      default: null,
    },
    wrap: Boolean,
    filter: {
      type: String,
      default: 'status',
    },
  },
  setup(props, { emit }) {
    const router = useRouter()
    const route = useRoute()

    const selected = ref(props.default || props.tabs[0].tab || props.tabs[0])

    const selectTab = (selectedTab) => {
      const tab = _get(selectedTab, 'tab', selectedTab)

      selected.value = tab
      if (props.changeRoute) {
        router.push({
          path: route.path,
          query: {
            page: route.query.page,
            [props.filter]: tab,
          },
        })
      }
      emit('change', selectedTab)
      emit('input', tab)
    }

    onMounted(() => {
      selected.value = props.value
    })

    watch(
      () => props.value,
      (val) => {
        selected.value = val
      }
    )

    return { props, selected, selectTab, _has }
  },
})
