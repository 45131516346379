//
//
//

import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    role: String,
    padding: {
      type: String,
      default: 'px-2 py-1',
    },
  },
  setup(props) {
    const colors = {
      ROLE_OWNER: 'bg-[#d9f3ea] border-[#d9f3ea] text-[#31a37a]',
      ROLE_ADMIN: 'bg-yellow-100 border-yellow-100 text-yellow-600',
      ROLE_ACCOUNTANT: 'bg-[#f8d3db] border-[#f8d3db] text-[#e45172]',
      ROLE_QUOTE_MANAGER: 'bg-blue-100 border-blue-100 text-blue-500',
      ROLE_SALES_MANAGER: 'bg-brand-100 border-brand-100 text-brand-500',
      ROLE_GHOST: 'bg-gray-50 border border-gray-200',
    }

    return { colors }
  },
})
