//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed, defineComponent, inject } from '@nuxtjs/composition-api'

import {
  add,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  getDay,
  isValid,
  isBefore,
  isAfter,
  isSameDay,
  isSameMonth,
  isWithinInterval,
  startOfMonth,
  startOfWeek,
  sub,
  differenceInCalendarDays,
  isMonday,
  previousMonday,
  nextSunday,
  isSunday,
} from 'date-fns'

import { fr } from 'date-fns/locale'

export default defineComponent({
  props: {
    isPrev: Boolean,
    hide: Function,
  },
  setup(props, { emit }) {
    const isDateRange = inject('isDateRange')
    const calendarDate = inject('calendarDate')
    const date = inject('date')
    const dateRange = inject('dateRange')
    const selectionRange = inject('selectionRange')
    const selectionRangeStart = inject('selectionRangeStart')

    const minDate = inject('minDate')
    const maxDate = inject('maxDate')

    const currentCalendarDate = computed(() =>
      props.isPrev === true ? sub(calendarDate.value, { months: 1 }) : calendarDate.value
    )

    const hasActiveSelectionRange = computed(
      () => selectionRange.value.start !== null || selectionRange.value.end !== null
    )

    const toggleSelectionRange = (day) => {
      if (isDateRange.value) {
        if (selectionRange.value.start === null) {
          selectionRangeStart.value = day
          selectionRange.value.start = day
          selectionRange.value.end = day
        } else {
          dateRange.value = selectionRange.value
          emit('updateDateRange', selectionRange.value)
          selectionRange.value = { start: null, end: null }
        }
      } else {
        date.value = day
        emit('updateDate', day)
      }
    }

    const setSelectionRange = (day) => {
      if (isDateRange.value) {
        if (hasActiveSelectionRange.value) {
          if (isBefore(day, selectionRangeStart.value)) {
            selectionRange.value.end = selectionRangeStart.value
            selectionRange.value.start = day
          } else {
            selectionRange.value.start = selectionRangeStart.value
            selectionRange.value.end = day
          }
        }
      }
    }

    const isWithinSelectionRange = (day) => isWithinInterval(day, selectionRange.value)
    const isStartSelectionRange = (day) => {
      return isSameDay(day, selectionRange.value.start)
    }

    const firstDayOfMonth = computed(() => startOfMonth(currentCalendarDate.value))
    const lastDayOfMonth = computed(() => endOfMonth(currentCalendarDate.value))

    const firstCalendarDay = computed(() => {
      let day = firstDayOfMonth.value
      if (getDay(day) > 0) {
        day = sub(day, { days: getDay(day) - 1 })
      }
      return day
    })

    const lastCalendarDay = computed(() => {
      let day = lastDayOfMonth.value
      if (getDay(day) !== 0) {
        day = add(day, { days: 7 - getDay(day) })
      }
      return day
    })

    const currentMonth = computed(() => {
      return {
        start: firstCalendarDay.value,
        end: lastCalendarDay.value,
      }
    })

    const weekdays = eachDayOfInterval({
      start: startOfWeek(new Date(), { weekStartsOn: 1 }),
      end: endOfWeek(new Date(), { weekStartsOn: 1 }),
    }).reduce((arr, day) => {
      arr.push(format(day, 'EEEEE', { locale: fr }))
      return arr
    }, [])

    const currentDays = computed(() => {
      let start = currentMonth.value.start
      let end = currentMonth.value.end

      if (!isMonday(start)) start = previousMonday(start)
      if (!isSunday(end)) end = nextSunday(end)

      return eachDayOfInterval({
        start,
        end,
      }).map((day) => {
        let disabled = false

        if (minDate.value && differenceInCalendarDays(minDate.value, day) > 0) {
          disabled = true
        }

        if (maxDate.value && differenceInCalendarDays(day, maxDate.value) > 0) {
          disabled = true
        }

        const isSelectionRangeStart = isDateRange.value && isSameDay(day, selectionRange.value.start)

        const isSelectedRangeStart = isDateRange.value && dateRange.value && isSameDay(day, dateRange.value.start)

        const isSelectionRangeEnd = isDateRange.value && isSameDay(day, selectionRange.value.end)

        const isSelectedRangeEnd = isDateRange.value && dateRange.value && isSameDay(day, dateRange.value.end)

        return {
          day,
          disabled,
          isSelectedDay: isValid(date.value) && isSameDay(day, date.value),
          isCurrentMonth: isSameMonth(currentCalendarDate.value, day),
          isWithinInterval: isDateRange.value && dateRange.value && isWithinInterval(day, dateRange.value),
          isSelectionRangeStart,
          isSelectedRangeStart,
          isSelectionRangeEnd,
          isSelectedRangeEnd,
          isSelectionBoundary:
            (isSelectionRangeStart && hasActiveSelectionRange.value) ||
            (isSelectedRangeStart && !hasActiveSelectionRange.value) ||
            (isSelectionRangeEnd && hasActiveSelectionRange.value) ||
            (isSelectedRangeEnd && !hasActiveSelectionRange.value),
        }
      })
    })

    const canPrevMonth = computed(() => {
      if (isValid(minDate.value)) {
        if (isSameMonth(currentCalendarDate.value, minDate.value)) return false
        if (isAfter(minDate.value, currentCalendarDate.value)) return false
      }

      return true
    })

    const canNextMonth = computed(() => {
      if (isValid(maxDate.value)) {
        if (isSameMonth(currentCalendarDate.value, maxDate.value)) return false
        if (isBefore(maxDate.value, currentCalendarDate.value)) return false
      }

      return true
    })

    const monthName = computed(() => format(currentCalendarDate.value, 'MMMM', { locale: fr }))

    const nextMonth = () => {
      calendarDate.value = add(calendarDate.value, { months: 1 })
    }
    const prevMonth = () => {
      calendarDate.value = sub(calendarDate.value, { months: 1 })
    }

    return {
      props,
      prevMonth,
      nextMonth,
      monthName,
      format,
      isSameMonth,
      currentCalendarDate,
      weekdays,
      currentDays,
      hasActiveSelectionRange,
      isDateRange,
      date,
      canPrevMonth,
      canNextMonth,
      setSelectionRange,
      isWithinSelectionRange,
      isStartSelectionRange,
      toggleSelectionRange,
    }
  },
})
