//
//
//

import { defineComponent, onMounted } from '@nuxtjs/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'

export default defineComponent({
  setup() {
    const { useMutations } = createNamespacedHelpers('documents')
    const { resetState } = useMutations(['resetState'])
    onMounted(() => {
      window.onbeforeunload = undefined
      resetState()
    })
  },
})
