import { get as _get } from 'lodash-es'
import parsePhoneNumber from 'libphonenumber-js'
import { countriesList } from '@/constants/countries'
import { isSameYear, isSameMonth, format } from 'date-fns'

export const normalizeString = (str) =>
  str != null
    ? str
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
    : ''

export const stripHTML = (str) => (![null, undefined].includes(str) ? str.replace(/(<([^>]+)>)/gi, '') : '')

export const breakProductName = (str) => {
  const blocks = [...str.matchAll(/<div>(.*?)<\/div>/g)].map((el) => _get(el, 1, '')).filter(Boolean)

  // Breaks the product name in multiple lines if containing divs
  if (blocks.length) {
    let text = _get(blocks, 0, '')
    const secondLine = _get(blocks, 1, '')

    if (`${text} ${secondLine}`.length < 200) {
      text += `<br />${secondLine}`
      if (blocks.length > 2) {
        text += ' [...]'
      }
    } else {
      text += `<br />${secondLine.split(' ').splice(0, 8).join(' ')}`
      if (blocks.length > 1) {
        text += ' [...]'
      }
    }

    return text
  }

  return str
}

export const cloneUnobserved = (obj) => JSON.parse(JSON.stringify(obj))

export const isEmptyAddress = (address) =>
  Object.values(address).filter(Boolean).length === 0 ||
  (Object.values(address).filter(Boolean).length === 1 && _get(address, 'country'))

const normalizeAddress = (data) => {
  let address = data
  ;['street', 'complement', 'postal_code', 'city', 'country'].forEach((component) => {
    if (!_has(address.address, component)) {
      address.address[component] = null
    }
  })
  return address
}

const cleanAddress = (data) => {
  let address = data
  if (isEmptyAddress(address.address)) {
    return null
  }

  address.address = Object.entries(address.address).reduce((obj, [k, v]) => {
    if (v === '') {
      v = null
    }
    obj[k] = v
    return obj
  }, {})

  const { key, ...cleanAddress } = address

  return cleanAddress
}

export const normalizeAddresses = (addresses) => addresses.map((address) => normalizeAddress(address))

export const cleanAddresses = (addresses) => {
  let data = addresses.map((address) => cleanAddress(address)).filter(Boolean)

  if (data.length) {
    let hasDefaultAddress = false
    for (const address of data) {
      if (_get(address, 'primary', null) === true) {
        hasDefaultAddress = true
        break
      }
    }

    if (!hasDefaultAddress) {
      data[0].primary = true
    }
  }

  return data
}

export const getAddressString = (addr) => {
  if (addr === null) return ''

  // Reorder address
  let address = {
    street: addr.street,
    complement: addr.complement,
    city: [addr.postal_code, addr.city].filter(Boolean).join(' '),
    country: addr.country,
  }

  return Object.entries(address)
    .reduce((arr, [k, v]) => {
      if (k === 'country') {
        v = countriesList[v]
      }
      arr.push(v)
      return arr
    }, [])
    .filter(Boolean)
    .join(', ')
}

export const sameAddresses = (address1, address2) => JSON.stringify(address1) === JSON.stringify(address2)

export const getPhoneNumber = (phone, targetCountry = undefined) => {
  if (!phone) return null
  const phoneNumber = parsePhoneNumber(phone)
  if (!phoneNumber) return null
  if (targetCountry && !phoneNumber.getPossibleCountries().includes(targetCountry.toUpperCase())) {
    return phoneNumber.formatInternational()
  }
  return phoneNumber.formatNational()
}

export const getDateRange = (range) => {
  if (!range.start || !range.end) {
    if (range.start) {
      return `Le ${format(new Date(String(range.start)), 'dd MMM yyyy')}`
    }
    if (range.end) {
      return `Le ${format(new Date(String(range.end)), 'dd MMM yyyy')}`
    }
    return '–'
  }

  const startDate = new Date(String(range.start))
  const endDate = new Date(String(range.end))

  if (isSameYear(startDate, endDate)) {
    if (isSameMonth(startDate, endDate)) {
      return `Du ${format(startDate, 'dd')} au ${format(endDate, 'dd MMM')}`
    } else {
      return `Du ${format(startDate, 'dd MMM')} au ${format(endDate, 'dd MMM')}`
    }
  } else {
    if (isSameYear(endDate, new Date())) {
      return `Du ${format(startDate, 'dd MMM yyyy')} au ${format(endDate, 'dd MMM')}`
    } else {
      return `Du ${format(startDate, 'dd MMM yyyy')} au ${format(endDate, 'dd MMM yyyy')}`
    }
  }
}
