//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  DOCUMENT_STATUS_PAID,
  DOCUMENT_STATUS_SCHEDULED,
  DOCUMENT_STATUS_TO_PAY,
  DOCUMENT_STATUS_TO_REVIEW,
  DOCUMENT_SUBTYPE_CREDIT,
} from '@/constants/documents'
import { differenceInCalendarDays } from 'date-fns'
import useModal from '@/composables/modal'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import {
  computed,
  defineComponent,
  ref,
  useContext,
  useFetch,
  watch,
  onMounted,
  onBeforeUnmount,
  nextTick,
} from '@nuxtjs/composition-api'
import { useLoading } from '@/composables/loading'
import Teleport from 'vue2-teleport'
import { pluralizeType } from '@/api/resources'

import { useStatus } from '@/composables/document'
import { get as _get } from 'lodash-es'
import { nanoid } from 'nanoid/non-secure'

export default defineComponent({
  components: {
    VuePdfEmbed,
    Teleport,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const modal = useModal()
    const id = ref(nanoid())

    const trans = 'contacts.'
    const { loaders, isLoading, setLoading } = useLoading()
    const currentTab = ref('quotes')
    const invoiceModal = ref(null)
    const deleteModal = ref(null)
    const previewModal = ref(null)
    const invoice = ref(null)
    const file = ref(null)

    const pluralType = computed(() => pluralizeType[`supplier_${_get(invoice.value, 'type', 'invoice')}`])

    const handleDelete = () => {
      modal.closeModal()
    }

    const { $supplierInvoicesRepository, $bus } = useContext()

    const { fetch, fetchState } = useFetch(async () => {
      if (modal.isModalOpen()) {
        const { data } = await $supplierInvoicesRepository.find(props.id, { cdn: 1, _expand: ['projects'] })
        invoice.value = data
        file.value = data.file
      }
    })

    const { setStatus } = useStatus($supplierInvoicesRepository)

    const changeStatus = async function (status) {
      try {
        setLoading(true, `set_status_${status}`)
        await setStatus(invoice.value, status)

        $bus.emit('update.item', {
          type: 'supplier_invoice',
          data: { ...invoice.value, status },
        })

        invoice.value.status = status
      } catch (err) {
        console.log(err)
      } finally {
        setLoading(false, `set_status_${status}`)
      }
    }

    const availableStatuses = computed(() => {
      if (invoice.value.type === DOCUMENT_SUBTYPE_CREDIT) {
        switch (invoice.value.status) {
          case DOCUMENT_STATUS_TO_PAY:
            return [DOCUMENT_STATUS_PAID]
          case DOCUMENT_STATUS_PAID:
            return [DOCUMENT_STATUS_TO_PAY]
        }
      }

      switch (invoice.value.status) {
        case DOCUMENT_STATUS_TO_REVIEW:
          return [DOCUMENT_STATUS_TO_PAY, DOCUMENT_STATUS_PAID]
        case DOCUMENT_STATUS_TO_PAY:
          return [DOCUMENT_STATUS_SCHEDULED, DOCUMENT_STATUS_PAID]
        case DOCUMENT_STATUS_SCHEDULED:
          return [DOCUMENT_STATUS_PAID]
      }
    })

    watch(
      () => modal.isModalOpen(),
      (val) => val && fetch()
    )

    watch(
      () => props.id,
      (val) => val && modal.isModalOpen() && fetch()
    )

    onMounted(() => {
      $bus.on('update.item', ({ type, data }) => {
        if (data && data.id === _get(invoice.value, 'id')) {
          invoice.value = data
        }
      })
    })

    const panel = ref(null)

    async function rendered() {
      await nextTick()
      if (panel.value) {
        panel.value.refreshScroll()
      }
    }

    return {
      ...modal,
      loaders,
      pluralType,
      isLoading,
      props,
      invoice,

      file,
      trans,
      fetchState,
      currentTab,
      deleteModal,
      previewModal,
      invoiceModal,
      availableStatuses,
      panel,
      rendered,
      handleDelete,
      changeStatus,
      differenceInCalendarDays,
      DOCUMENT_STATUS_TO_REVIEW,
      DOCUMENT_STATUS_TO_PAY,
      DOCUMENT_SUBTYPE_CREDIT,
      DOCUMENT_STATUS_SCHEDULED,
      DOCUMENT_STATUS_PAID,
    }
  },
})
